let firebaseModule, firebaseApp, firestore, functions;

const initFirebase = () => {
    const firebasePrm = import('firebase/app').then(imp => {
        firebaseModule = imp;

        const productionConfig = {
            apiKey: "AIzaSyCKIq8276DxLRkUEHYhiADZFhiYeXH5mss",
            authDomain: "alfalistv2.firebaseapp.com",
            databaseURL: "https://alfalistv2.firebaseio.com",
            projectId: "alfalistv2",
            storageBucket: "alfalistv2.appspot.com",
            messagingSenderId: "946053184699",
            appId: "1:946053184699:web:8752a5f3fd2d057a22fd9e",
            measurementId: "G-SR3GFV4MKY"  // beta channel (app: alfalist-beta-widget, site: alfalist-widget-rev3)
        };

        // In case of duplicate call, we don't want to re-initialize firebase
        const apps = firebaseModule.default.apps;
        const existingApp = apps.find(app => app['name_'] === "alfalist-widget-rev4");  // See name config below

        if (existingApp){
            firebaseApp = existingApp;
        } else {
            firebaseApp = firebaseModule.default.initializeApp(productionConfig, "alfalist-widget-rev4");
        }
    });

    const firestorePrm = import('firebase/firestore');
    // const performancePrm = import('firebase/performance');

    return Promise.all([firebasePrm, firestorePrm]).then(() => {
        firestore = firebaseApp.firestore();
        // performanceMonitoring = firebaseApp.performance();
        return true;
    });
};

const initFirebaseFunctions = () => {
    if (functions){return Promise.resolve(true);}

    return import('firebase/functions').then(() => {
        if (!firebaseApp){throw new Error('init firebase/functions, firebase not loaded yet.');}
        functions = firebaseApp.functions();
        return true;
    });
};

const getFirestore = () => {
    return firestore;
};

const getFunctions = () => {
    return functions;
};

// const getPerformanceMonitoring = () => {
//     return performanceMonitoring;
// };

export {initFirebase, initFirebaseFunctions, getFirestore, getFunctions};