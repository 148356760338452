/**
 * This function takes the slots calculated by listenForAvailSlots and returns the slots that can be picked amongst
 * @param slots {Object} The result from listenforAvilSlots
 * @param serviceId {string}
 * @param providerIds {Array<String>} Providers to include (can be array of all providers offering the service)
 * @param data {Object} The current data from FirestoreContext
 * @returns {Object} Pickable slots by venue Id
 */
const getPickableSlots = (slots, serviceId, providerIds, data) => {
    const relevantVenueIds = Object.keys(slots);
    const service = data.services[serviceId];

    // Get slot size for each venue
    const slotSizeByVenueId = {};
    Object.keys(data.venues).forEach(venueId => {
        slotSizeByVenueId[venueId] = 15;
        // slotSizeByVenueId[venueId] = data.venues[venueId].slotSizeOverride || data.policies.slotSize;
    });

    // Get service duration for each provider
    const serviceDurationByProviderId = {};
    providerIds.forEach(providerId => {
        const durationInSlotSize = slotSizeByVenueId[data.providers[providerId].venue];

        if (
            service.hasOwnProperty('durationProviderOverride') &&
            service.durationProviderOverride.hasOwnProperty(providerId) &&
            typeof service.durationProviderOverride[providerId] === 'number'
        ){
            serviceDurationByProviderId[providerId] = Math.ceil(service.durationProviderOverride[providerId] / durationInSlotSize);
        } else {
            serviceDurationByProviderId[providerId] = Math.ceil(service.defaultDuration / durationInSlotSize);
        }
    });

    const res = {};
    relevantVenueIds.forEach(venueId => {
        const relevantProviderIds = providerIds.filter(providerId => data.providers[providerId].venue === venueId);
        const venueSlots = slots[venueId];
        res[venueId] = [];

        for (let i=0; i<venueSlots.length; i++){
            for (let providerId of relevantProviderIds){
                const duration = serviceDurationByProviderId[providerId];

                // If end of day, and not enough time to finish before the end of the window
                const remainingSlotCount = venueSlots.length - i;
                if (duration > remainingSlotCount){break;}

                // Check this, and the following slots to see if they're free
                let providerAvail = true;
                for (let j=0; j<duration; j++){
                    if (!venueSlots[i + j].availableByProviderId[providerId]){
                        providerAvail = false;
                        break;
                    }
                }

                // If this is a half-slot we need to check if it's active
                const slot = venueSlots[i];
                if (slot.isHalfSlot && !slot.halfSlotActiveByProvider[providerId]){
                    providerAvail = false;
                }

                if (providerAvail){
                    res[venueId].push(venueSlots[i]);
                    break;
                }
            }
        }
    });

    // console.log(res, slots, serviceId, providerIds, data);
    return res;
};

export default getPickableSlots;