import React, {useContext, useEffect, useMemo, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {FirestoreContext} from "../../../contexts/FirestoreContextV2";
import createAlternativeListeners from "../../../logic/calendar/slotListener/createAlternativeListeners";

const ServiceAlternatives = props => {
    const data = useContext(FirestoreContext);
    const classes = useStyles();
    const {selectedServiceId, startOfDayTs, endOfDayTs, venueId, setSelection} = props;

    // *************************************************
    // ***** Find and filter service alternatives ******
    // *************************************************

    const alternatives = useMemo(() => {
        if (typeof selectedServiceId !== "string" || selectedServiceId.length === 0){return null;}
        if (
            !data.serviceAlternatives ||
            !data.serviceAlternatives.data ||
            !data.serviceAlternatives.data[selectedServiceId] ||
            !data.serviceAlternatives.data[selectedServiceId].alternatives
        ){return null;}  // Confirm structure

        // Confirm that it's an array
        const alts = data.serviceAlternatives.data[selectedServiceId].alternatives;
        if (!Array.isArray(alts)){return null;}

        return alts.filter(alt => {
            if (!alt.enabled){return false}
            if (!data.services.hasOwnProperty(alt.alternativeServiceId)){return false;}
            if (data.services[alt.alternativeServiceId].hidden){return false;}
            return true;
        });
    }, [data, selectedServiceId]);

    const displayAtOnce = useMemo(() => {
        if (!alternatives || alternatives.length === 0){return 0;}

        return data.serviceAlternatives.data[selectedServiceId].displayAtOnceCount || 1;
    }, [data, selectedServiceId, alternatives]);

    // ***********************************************************
    // ******** Create available listeners for alt services ******
    // ***********************************************************

    // This effect handles the creation of all listeners
    const [listenerData, setListenerData] = useState(null);
    useEffect(() => {
        if (!alternatives || alternatives.length === 0){return;}  // if null or

        return createAlternativeListeners(
            data,
            startOfDayTs,
            endOfDayTs,
            alternatives,
            displayAtOnce,
            venueId,
            setListenerData
        )
    }, [data, alternatives, displayAtOnce, startOfDayTs, endOfDayTs, venueId]);

    // ***********************************************
    // **************** Render ***********************
    // ***********************************************

    if (!alternatives || alternatives.length === 0){return null;}
    if (!listenerData || listenerData.length === 0){return null;}

    const display = listenerData.map(l => {
        const alt = l.alternative;
        const pickableSlots = l.pickableSlots;

        if (Object.values(pickableSlots).every(v => v.length === 0)){return null;}  // Alternative have no available slots, hide it

        console.log(l);

        return (
            <Typography
                key={'alternative_' + l.alternativeIndex.toString()}
                component={'div'}
                variant={"caption"}
                onClick={() => {
                    setSelection('serviceId', alt.alternativeServiceId);
                    props.setProviderFilter('any');
                }}
                className={classes.link}
            >
                { alt.text }
            </Typography>
        );
    });

    return <React.Fragment>{ display }</React.Fragment>;
};

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.text.primary,
        '&:hover': {color: theme.palette.primary.main},
        cursor: 'pointer',
        textDecoration: 'underline',
        textAlign: 'center'
    }
}));

export default ServiceAlternatives;