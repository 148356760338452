import React, {useContext} from 'react';

// Core components
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import {LocaleStringsContext} from "../../../contexts/LocaleStringsContext";
import StepLabel from "@material-ui/core/StepLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useMediaQuery} from "@material-ui/core";

const MaterialProgress = props => {
    const locale = useContext(LocaleStringsContext);
    const classes = useStyles();

    // If the screen is very small -> use a smaller font
    const smallScreenFallback = useMediaQuery('(max-width: 320px)');
    // console.log(smallScreenFallback);

    // const steps = ['services', 'time', 'customerInfo', 'confirmation'].map(step => locale.stepper[step]); // Removed: data.design.selectionOrder, force order

    const handleClick = stepIndex => () => {
        if (stepIndex >= props.onPage){return;}
        props.goToPage(stepIndex);
    };

    return(
        <Stepper activeStep={props.onPage} className={classes.stepper + (smallScreenFallback ? ' ' + classes.smallLabel : '')} alternativeLabel>
            <Step onClick={handleClick(0)} className={classes.step}><StepLabel>Velg behandling</StepLabel></Step>
            <Step onClick={handleClick(1)} className={classes.step}><StepLabel>Velg tidspunkt</StepLabel></Step>
            <Step onClick={handleClick(2)} className={classes.step}><StepLabel>Din informasjon</StepLabel></Step>
            <Step onClick={handleClick(3)} className={classes.step}><StepLabel>Bekreft booking</StepLabel></Step>
        </Stepper>
    )
};

const useStyles = makeStyles(theme => ({
    step: {
        cursor: 'pointer'
    },
    stepper: {
        marginBottom: '1rem',
        [theme.breakpoints.down("sm")]: {
            paddingLeft: '0.25rem',
            paddingRight: '0.25rem',
        },
        [theme.breakpoints.down('xs')]: {
            '&>div>span>span>span': {
                fontSize: '0.80rem'
            }
        }
    },
    smallLabel: {
        '&>div>span>span>span': {
            fontSize: '0.75rem!important',
        },
        paddingLeft: '0',
        paddingRight: '0'
    }
}));

export default MaterialProgress;