import React, {useContext, useEffect, useMemo, useState} from 'react';
import SimpleConfirmation from "./SimpleConfirmation/SimpleConfirmation";
import {FirestoreContext} from "../../contexts/FirestoreContextV2";
import {LocaleStringsContext} from "../../contexts/LocaleStringsContext";
import getCountryNamesByLocale from "../../logic/country/getCountryNamesByLocale";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularLoading from "../../components/loading/CircularLoading";
import {getFunctions, initFirebaseFunctions} from "../../logic/firebaseConfig";
import {getMoment} from "../../logic/timeHandler";
import {TrackingContext} from "../../contexts/TrackingContextV3";
import {ErrorReportingContext} from "../../contexts/ErrorBoundary";
import {DebugModeContext} from "../../contexts/DebugModeContext";
import getAnalyticsValue from "../../logic/tracking/getAnalyticsValue";
import getAnalyticsItemFromService from "../../logic/tracking/getAnalyticsItemFromService";

const Confirmation = props => {
    const data = useContext(FirestoreContext);
    const locale = useContext(LocaleStringsContext);
    const track = useContext(TrackingContext);
    const errorReporting = useContext(ErrorReportingContext);
    const debugMode = useContext(DebugModeContext);
    const classes = useStyles();
    const template = data.design.confirmation.template;

    const [bookingTermsError, setBookingTermsError] = useState(false);

    // This effect resets the error message when the terms are accepted
    useEffect(() => {
        if (props.selection.bookingTermsCheckboxChecked){
            setBookingTermsError('');
        }
    }, [props.selection.bookingTermsCheckboxChecked]);

    const [confirmationPending, setConfirmationPending] = useState(false);
    const handleConfirmation = async bookingTermsImplicitlyAccepted => {
        // Confirm booking terms
        if (
            data.policies.consentCheckboxes.bookingTerms &&
            !props.selection.bookingTermsCheckboxChecked
        ){
            setBookingTermsError(true);
            return;
        }

        setConfirmationPending(true);

        // Call cloud function here

        let functions = getFunctions();
        if (!functions){  // fail safe
            await initFirebaseFunctions();
            functions = getFunctions();
        }

        const moment = getMoment();
        const bookAppointment = functions.httpsCallable('bookAppointment');

        const selection = {...props.selection};

        // We want to send the timestamp to the server, not the moment object
        selection.tsStart = selection.aptStartMoment.unix();
        delete selection.aptStartMoment;

        // In the case of "by clicking confirm you agree to tos"
        selection.bookingTermsImplicitlyAccepted = bookingTermsImplicitlyAccepted;

        // We only want to send the customer info values, not the errors or validation data
        const customerInfo = {};
        Object.entries(props.info).forEach(([key, value]) => customerInfo[key] = value.value);

        const submissionData = {
            businessId: data.businessId,
            designId: data.designId,
            selection: selection,
            customerInfo: customerInfo,
            clientInfo: {
                clientTz: moment.tz.guess(),
                clientCurrentTimestamp: moment().unix()
            },
            visit: data.visit,
            testMode: debugMode.mockBooking
        };

        console.log('Submitting data: ', submissionData);

        bookAppointment(submissionData).then(res => {
            const result = res.data;

            track("event", "booking", {
                service_id: selection.serviceId,
                venue_id: selection.venueId,
                provider_filter: selection.providerFilter
            });

            console.log('%cSuccess: ', 'color:green', result);
            props.goToPage('next');
        }).catch(err => {
            console.error(err);
            track("event", "booking_error");
            setConfirmationPending(false);
        });
    };

    const displayValues = useMemo(() => {
        const service = data.services[props.selection.serviceId];
        const venue = data.venues[props.selection.venueId];
        const providerId = props.selection.providerFilter !== 'any' ? props.selection.providerFilter : null;
        const countryNames = getCountryNamesByLocale(data.design.locale.strings);

        // Service duration formatting
        let duration = service.defaultDuration;
        if (
            providerId &&
            service.hasOwnProperty('durationProviderOverride') &&
            service.durationProviderOverride.hasOwnProperty(providerId)
        ){
            duration = service.durationProviderOverride[providerId];
        }

        let displayDuration;
        if (duration > 90){
            const h = Math.floor(duration / 60);
            const m = Math.floor(duration % 60);

            const displayH = `${h.toString()} ${locale.services.durationIntervals[h > 1 ? 'hours' : 'hour']}`;
            const displayM = `${m.toString()} ${locale.services.durationIntervals[m > 1 ? 'minutes' : 'minute']}`;

            displayDuration = m > 0 ? displayH + ' ' + displayM : displayH;
        } else {
            displayDuration = duration.toString() + ' ' + locale.services.durationIntervals.minutes;
        }

        // Provider name formatting
        let displayProvider;
        if (!providerId){
            displayProvider = locale.providerSelect.any;
        } else {
            displayProvider = data.providers[providerId].name || locale.providerSelect.unnamedProvider;
        }

        // Customer name
        const customerInfo = {
            email: props.info.email.value || '',
            address: props.info.address.value || '',
            zip: props.info.zip.value || '',
            city: props.info.city.value || '',
            notes: props.info.notes.value || '',
            name: '',
            country: '',
            phone: ''
        };

        // Format name
        if (props.info.fullName){
            customerInfo.name = props.info.fullName.value;
        } else {
            customerInfo.name = (props.info.firstName.value || '') + ' ' + (props.info.lastName.value || '');
        }

        // Format country
        if (props.info.country){
            customerInfo.country = countryNames[props.info.country.value] || '';
        } else {
            customerInfo.country = '';
        }

        // Format phone number
        if (props.info.phone.value && props.info.phoneCC.value){
            customerInfo.phone = `+${props.info.phoneCC.value} ${props.info.phone.value}`;
        }

        // Selected time
        const time = props.selection.aptStartMoment.format('LLL');

        return({
            service: {
                name: service.name || locale.services.unnamedService,
                price: service.price,
                duration: displayDuration,
                provider: displayProvider
            },
            customerInfo: customerInfo,
            time: time,
            venue: {
                name: venue.name,
                addressLineOne: venue.addressLineOne || '',
                addressLineTwo: venue.addressLineTwo || '',
                zip: venue.zip || '',
                city: venue.city || ''
            }
        });

    }, [props.selection, props.info, data, locale]);

    // If confirmation is pending
    if (confirmationPending){
        return(
            <div className={classes.confirmationPendingWrapper}>
                <CircularLoading/>
                <Typography variant={'h5'}>{ locale.confirmation.confirmationPending }</Typography>
            </div>
        )
    }

    const confirmationProps = {
        info: props.info,
        selection: props.selection,
        setSelection: props.setSelection,
        handleConfirmation: handleConfirmation,
        confirmationPending: confirmationPending,
        displayValues: displayValues,
        bookingTermsError: bookingTermsError
    };

    switch (template) {
        case "SimpleConfirmation":
            return <SimpleConfirmation {...confirmationProps}/>;
        default:
            console.error('Could not load confirmation template for: ', template);
            return <SimpleConfirmation {...confirmationProps}/>;
    }
};

const useStyles = makeStyles(theme => ({
    confirmationPendingWrapper: {
        textAlign: 'center'
    }
}));

export default Confirmation;