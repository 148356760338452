import useTheme from "@material-ui/core/styles/useTheme";
import {useMediaQuery} from "@material-ui/core";

/**
 * Note: useMediaQuery does not work immediately on page load, (only after html is rendered?), so a quick 'xs' might be
 * delivered if useCurrentBreakpoint is used on the landing page. (191008 - current implementation only uses this to
 * determine if browser should scroll to top automatically when going to a new page or not, so no issue).
 */

const useAllBreakpoints = () => {
    const theme = useTheme();

    const xl = useMediaQuery(theme.breakpoints.up('xl'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const xs = useMediaQuery(theme.breakpoints.up('xs'));

    return [['xl', xl], ['lg', lg], ['md', md], ['sm', sm], ['xs', xs]];
};

const useCurrentBreakpoint = () => {
    const allBreakpoints = useAllBreakpoints();
    const res = allBreakpoints.find(bp => bp[1]);

    if (!res){return 'xs';}

    // console.log(res[0]);

    return res[0];
};

export { useAllBreakpoints, useCurrentBreakpoint };