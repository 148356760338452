import React, {useContext} from 'react';

import {FirestoreContext} from "../../../contexts/FirestoreContextV2";
import {LocaleStringsContext} from "../../../contexts/LocaleStringsContext";
import fillPlaceholders from "../../../logic/placeholderText/fillPlaceholders";
import {getMoment} from "../../../logic/timeHandler";

// Core components
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";

const SimpleThankYou = props => {
    const { selection } = props;

    const data = useContext(FirestoreContext);
    const locale = useContext(LocaleStringsContext);
    const classes = useStyles();
    const moment = getMoment();

    let heading;
    if (data.design.thankYou.overrideHeading){
        heading = data.design.thankYou.overrideHeading;
    } else {
        heading = locale.thankYou.heading;
    }

    let body;
    if (data.design.thankYou.overrideBody){
        body = data.design.thankYou.overrideBody.split('*newline*').map((line, index) => {
            const txt = fillPlaceholders(
                line,
                data,
                moment(selection.tsStart),
                selection.serviceId,
                selection.venueId
            );

            return(
                <Typography component={'p'} key={'body_line_' + index.toString()} className={classes.paragraph}>
                    { txt }
                </Typography>
            );
        });
    } else {
        body = <Typography component={'p'}>{locale.thankYou.body}</Typography>;
    }

    return(
        <Grid container className={classes.outerGrid} justifyContent={'center'}>
            <Grid item className={classes.innerGrid}>
                <Typography variant={'h5'} className={classes.heading}>{ heading }</Typography>
                { body }
            </Grid>
        </Grid>
    )
};

const useStyles = makeStyles(theme => ({
    outerGrid: {
        width: '100%'
    },
    innerGrid: {
        maxWidth: '35rem',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '1rem'
    },
    paragraph: {
        marginBottom: '0.5rem'
    }
}));

export default SimpleThankYou;