import React, {useContext, useMemo} from 'react';
import PropTypes from 'prop-types';
// import {FirestoreContext} from "../../../../contexts/FirestoreContextV2";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ResponsivePicture from "../../../../components/images/ResponsivePicture";
import {LightboxContext} from "../../../../contexts/LightboxContext";
import {useAllBreakpoints} from "../../../../customHooks/useCurrentBreakpoint";

/**
 * This component can be used in multiple service designs, but was originally created as a final bottom row for
 * CardCategories. Images can be clicked to open a full screen gallery viewer.
 * @param props
 * @constructor
 */
const GalleryRow = props => {
    const { className, galleryMedia, galleryWidthByBreakpoint, imageCountByBreakpoint } = props;

    const classes = useStyles();
    const openLightbox = useContext(LightboxContext);

    // ****************************************
    // ******** Get current breakpoint ********
    // ****************************************

    const allBreakpoints = useAllBreakpoints();

    // ***********************************************
    // ******* Get number of images to display *******
    // ***********************************************

    const maxImageCount = useMemo(() => {
        const x = allBreakpoints.find(([key, active]) => {
            return active && imageCountByBreakpoint.hasOwnProperty(key);
        });

        if (!x){return 4;}  // Default

        return imageCountByBreakpoint[x[0]];
    }, [allBreakpoints, imageCountByBreakpoint]);

    if (galleryMedia.length === 0){throw new Error('GalleryRow should never be rendered with 0 mediaIds');}
    const imageCount = Math.min(galleryMedia.length, maxImageCount);

    // **********************************************
    // ******** Get css width of each image *********
    // **********************************************

    const imageWidthByBreakpoint = useMemo(() => {
        return allBreakpoints.map(([key, active], index) => {
            const x = allBreakpoints.slice(index)  // Find first matching breakpoint smaller or equal
                    .find(([key, active]) => {
                return active && galleryWidthByBreakpoint.hasOwnProperty(key);
            });

            if (!x){return `${(100 / imageCount).toString()}vw`;}

            return `calc(${galleryWidthByBreakpoint[x[0]]} / ${imageCount.toString()})`;
        });
    }, [imageCount, galleryWidthByBreakpoint, allBreakpoints]);

    // *********************************************
    // ************* Render images *****************
    // *********************************************

    const { imageContainer } = classes;
    const images = useMemo(() => {
        return galleryMedia.slice(0, maxImageCount).map((media, index) => {
            return(
                <div key={media.mediaId} className={imageContainer} onClick={() => {openLightbox(galleryMedia, index)}}>
                    <ResponsivePicture
                        formats={'25x25-Q20|40x40-Q80|60x60-Q80|150x150-Q80|175x175-Q80|250x250-Q80|320x320-Q80|375x375-Q80|414x414-Q80|540x540-Q80'}
                        mediaId={media.mediaId}
                        alt={media.desc || ''}
                        xl={imageWidthByBreakpoint[0]}
                        lg={imageWidthByBreakpoint[1]}
                        md={imageWidthByBreakpoint[2]}
                        sm={imageWidthByBreakpoint[3]}
                        xs={imageWidthByBreakpoint[4]}
                    />
                </div>
            )
        });
    }, [galleryMedia, maxImageCount, imageWidthByBreakpoint, imageContainer, openLightbox]);

    return (
        <div className={classes.gallery + (className ? ' ' + className : '')}>
            { images }
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    gallery: {
        overflow: 'hidden',
        display: 'flex'
    },
    imageContainer: {
        flexGrow: 1,
        '&:hover': {
            opacity: '0.8'
        },
        cursor: 'pointer'
    }
}));

GalleryRow.propTypes = {
    galleryMedia: PropTypes.arrayOf(PropTypes.exact({mediaId: PropTypes.string.isRequired, desc: PropTypes.string})).isRequired,
    className: PropTypes.string,
    imageCountByBreakpoint: PropTypes.exact({
        xs: PropTypes.number.isRequired,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number
    }),
    galleryWidthByBreakPoint: PropTypes.exact({
        xs: PropTypes.string.isRequired,
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
        xl: PropTypes.string
    })
};

export default GalleryRow;