import React from 'react';

// Core components
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

const CircularLoading = props => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <CircularProgress variant={"indeterminate"} color={'primary'} size={80}/>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        padding: '2rem 0',
        textAlign: 'center'
    }
}));

export default CircularLoading;