import React, {useContext} from 'react';
import {FirestoreContext} from "../../contexts/FirestoreContextV2";
import SimpleThankYou from "./SimpleThankYou/SimpleThankYou";
import {ErrorReportingContext} from "../../contexts/ErrorBoundary";

const ThankYou = props => {
    const data = useContext(FirestoreContext);
    const errorReporting = useContext(ErrorReportingContext);

    const template = data.design.thankYou.template;

    switch (template) {
        case 'SimpleThankYou':
            return <SimpleThankYou {...props}/>;
        default:
            errorReporting.nonBreakingError(new Error('ThankYou page, unexpected template value. Defaulting to SimpleThankYou'));
            return  <SimpleThankYou {...props}/>;
    }
};

export default ThankYou;