/**
 * Automatically retrieve they city based on the zip/postal code, and the alpha2 country code.
 * @param {string} CC
 * @param {string} zip
 * @return {Promise|null} - Promise if lookup is possible, otherwise null. Resolves to city
 */
const findCityByZip = (CC, zip) => {
    let prm;
    switch (CC) {
        case "NO":
            prm = import('./NO');
            break;
        case "DK":
            prm = import('./DK');
            break;
        // case "PL":
        //     prm = import('./PL');
        //     break;
        default:
            return null;  // No dataset for this CC
    }

    return prm.then(m => {
        const cityByZip = m.default;

        if (cityByZip.hasOwnProperty(zip)){
            return cityByZip[zip];
        } else {
            return null;
        }
    });
};

export default findCityByZip;


// window.fixPL = () => {
//     import('./PL').then(m => {
//         const res = {};
//         Object.entries(m.default).forEach(([key, value]) => {
//             const newKey = key.replace('-', "");
//             res[newKey] = value;
//         });
//
//         console.log(JSON.stringify(res));
//     });
// };