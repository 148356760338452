import React, {useContext, useMemo} from 'react';
import GalleryRow from "./GalleryRow";
import PropTypes from 'prop-types';
import {FirestoreContext} from "../../../../contexts/FirestoreContextV2";

/**
 * This is a thin wrapper around the GalleryRow component for use with categories. In the most bare-bone instance
 * only a categoryId has to be supplied. This wrapper handles finding the mediaIds. It also hides the gallery if
 * the category has no galleryMedia property.
 * @param props
 * @return {*}
 * @constructor
 */
const GalleryRowForCategory = props => {
    const { categoryId, minImageCount, className, imageCountByBreakpoint, galleryWidthByBreakpoint } = props;

    const data = useContext(FirestoreContext);
    const category = data.categories.hasOwnProperty(categoryId) ? data.categories[categoryId] : null;

    const galleryMedia = useMemo(() => {
        if (
            !category ||
            !category.hasOwnProperty('galleryMedia') ||
            !Array.isArray(category.galleryMedia) ||
            category.galleryMedia.length < (minImageCount || 1)
        ){return null;}

        return category.galleryMedia;
    }, [category, minImageCount]);

    // If no mediaIds are found, hide the gallery
    if (!galleryMedia){return null;}

    return <GalleryRow
        galleryMedia={galleryMedia}
        className={className}
        imageCountByBreakpoint={imageCountByBreakpoint}
        galleryWidthByBreakpoint={galleryWidthByBreakpoint}
    />;
};

GalleryRowForCategory.propTypes = {
    categoryId: PropTypes.string.isRequired,  // This id of the category, for which to display gallery
    minImageCount: PropTypes.number,  // The minimum amount of images needed in the galleryMedia for the gallery to display (defaults to 1)
    className: PropTypes.string,  // css class applied to the root element (can be used to set height)
    imageCountByBreakpoint: PropTypes.exact({
        xs: PropTypes.number.isRequired,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number
    }),
    galleryWidthByBreakPoint: PropTypes.exact({
        xs: PropTypes.string.isRequired,
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
        xl: PropTypes.string
    })
};

export default GalleryRowForCategory;