/**
 * This function takes an array of format alternatives (eg. ['xx xx xx xx', 'xxx xxxx'] and a string and returns the
 * correct format for that length of string.
 * @param {string} str
 * @param {Array<string>} formatAlternatives
 * @returns {string|null} null if no match. Otherwise returns the matching format
 */
const findMatchingFormat = (str, formatAlternatives) => {
    const matchingFormat = formatAlternatives
        .find(f => f.match(new RegExp(/x/g)).length === str.length);

    return matchingFormat ? matchingFormat : null;
};

export default findMatchingFormat;