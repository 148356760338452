import React, {useContext} from 'react';
import {LocaleStringsContext} from "../../contexts/LocaleStringsContext";
import {FirestoreContext} from "../../contexts/FirestoreContextV2";
// import getTosUrl from "../../logic/confirmationFormatting/getTosUrl";
import getPrivacyPolicyUrl from "../../logic/confirmationFormatting/getPrivacyPolicyUrl";

// Core components
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import getBookingTermsUrl from "../../logic/confirmationFormatting/getBookingTermsUrl";
import makeStyles from "@material-ui/core/styles/makeStyles";

const checkboxAlternatives = [
    'bookingTerms',
    'newsletter',
    'promotions'
];

const ConsentCheckboxes = props => {
    const locale = useContext(LocaleStringsContext);
    const data = useContext(FirestoreContext);
    const classes = useStyles();

    const displayConsentCheckboxes = (
        data.policies.consentCheckboxes.bookingTerms ||
        data.policies.consentCheckboxes.newsletter ||
        data.policies.consentCheckboxes.promotions
    );

    if (!displayConsentCheckboxes){return null;}

    const toggleCheckbox = field => () => {
        props.setSelection(field, !props.selection[field]);
    };

    // Create checkboxes
    const checkboxes = checkboxAlternatives
        .filter(checkboxType => data.policies.consentCheckboxes[checkboxType])
        .map(checkboxType => {
            let label = locale.confirmation.consentCheckboxes[checkboxType + 'Checkbox'];

            let field;
            let error = false;
            switch (checkboxType) {
                case "bookingTerms":
                    field = 'bookingTermsCheckboxChecked';
                    if (props.bookingTermsError){error = true;}

                    const parts = label.split('{bookingTerms}');
                    label = (
                        <React.Fragment>
                            { parts[0] }
                            {
                                <Link
                                    color={"primary"}
                                    variant={"inherit"}
                                    href={getBookingTermsUrl(data.businessId)}
                                    target={"_blank"}
                                    rel="noopener"
                                >
                                    { locale.confirmation.consentCheckboxes.bookingTermsLinkText }
                                </Link>
                            }
                            { parts[1] }
                        </React.Fragment>
                    );

                    break;
                case "newsletter":
                    field = 'subscribeToNewsletter';
                    break;
                case "promotions":
                    field = 'subscribeToPromotions';
                    break;
                default:
                    console.error('Unknown checkbox type: ', checkboxType);
            }

            return(
                <div key={checkboxType}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={"primary"}
                                className={error ? classes.errorCheckbox : ''}
                                checked={props.selection[field]}
                                onChange={toggleCheckbox(field)}
                            />
                        }
                        label={label}
                    />
                </div>
            );
        });

    // Create text below checkboxes - information about the privacy policy
    let privacyPolicyText = null;
    if (
        data.policies.consentCheckboxes.newsletter ||
        data.policies.consentCheckboxes.promotions
    ){
        const privacyPolicyTextTemplate = locale.confirmation.consentCheckboxes.consentNeededPrivacyPolicy;
        const privacyPolicyParts = privacyPolicyTextTemplate.split('{privacyPolicy}');
        privacyPolicyText = (
            <Typography variant={"caption"}>
                { privacyPolicyParts[0] }
                {
                    <Link
                        color={"primary"}
                        variant={"inherit"}
                        href={getPrivacyPolicyUrl(data.businessId)}
                        target={"_blank"}
                        rel="noopener"
                    >
                        { locale.confirmation.consentCheckboxes.consentNeededPrivacyPolicyLinkText }
                    </Link>
                }
                { privacyPolicyParts[1] }
            </Typography>
        );
    }

    return(
        <div className={classes.wrapper}>
            { checkboxes }
            { privacyPolicyText }
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '1rem 0'
    },
    errorCheckbox: {
        color: theme.palette.error.main
    }
}));

export default ConsentCheckboxes;