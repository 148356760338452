import React, {createContext} from 'react';

// Note: React does not yet have a hook for componentDidCatch

const ErrorReportingContext = createContext({});

class ErrorBoundary extends React.Component {
    errorCount = 0;
    userSession = Math.floor(Math.random() * 1000000);
    onErrorCallbacks = [];

    reportError = (error, errorInfo, optionalParams = {}) => {
        if (this.errorCount > 5){
            return;
        }

        const xhttp = new XMLHttpRequest();
        const url = 'https://us-central1-alfalistv2.cloudfunctions.net/recordError';
        let data;

        try {
            data = JSON.stringify({
                userSession: this.userSession,
                msg: error.message,
                name: error.name,
                url: null,
                lineNumber: error.lineNumber || null,
                columnNumber: error.columnNumber || null,
                stackTrace: error.stack || null,
                componentStack: errorInfo && errorInfo.hasOwnProperty('componentStack') ? errorInfo.componentStack : null,
                clientTs: Math.floor(new Date().valueOf() / 1000),
                caughtByErrorBoundary: true,
                reportingError: false,
                navAppCodeName: navigator.appCodeName || null,
                navName: navigator.appName || null,
                navVersion: navigator.appVersion || null,
                navCookieEnabled: navigator.cookieEnabled || null,
                navLanguage: navigator.language || null,
                navOnline: navigator.onLine || null,
                navPlatform: navigator.platform || null,
                navUserAgent: navigator.userAgent || null,
                breakingError: typeof optionalParams.breakingError === "boolean" ? error.breakingError : true
            });
        } catch (e) {
            console.error("Could not serialize error: ", e);
            data = JSON.stringify({
                reportingError: true,
                name: e.name,
                msg: e.message
            });
        }

        console.log("Submitting error data: ", data);

        xhttp.open("POST", url, true);
        xhttp.setRequestHeader('Content-Type', 'application/json');
        xhttp.send(data);
    };

    componentDidCatch(error, errorInfo) {
        this.fireOnErrorCallbacks(error);
        this.reportError(error, errorInfo);
    }

    // ************************************
    // ******** Context toolkit * *********
    // ************************************

    nonBreakingError = (error) => {
        console.error("non-breaking error: ", error);
        this.fireOnErrorCallbacks(error);
        this.reportError(error, {}, {breakingError: false});
    };

    getErrorCount = () => {
        return this.errorCount;
    };

    onError = callback => {
        this.onErrorCallbacks.push(callback);
        return () => {
            this.onErrorCallbacks = this.onErrorCallbacks.filter(cb => cb !== callback);
        };
    };

    fireOnErrorCallbacks = error => {
        this.onErrorCallbacks.forEach(cb => {
            if (typeof cb === "function"){
                cb(error);
            }
        });
    };

    errorToolkit = {
        nonBreakingError: this.nonBreakingError,
        getErrorCount: this.getErrorCount,
        onError: this.onError
    };

    render() {
        return(
            <ErrorReportingContext.Provider value={this.errorToolkit}>
                { this.props.children }
            </ErrorReportingContext.Provider>
        );
    }
}

export { ErrorBoundary, ErrorReportingContext };