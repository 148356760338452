import React from 'react';

// Core components
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

const LinearLoading = props => {
    const classes = useStyles();

    return(
        <LinearProgress
            color={"primary"}
            className={classes.progress}
        />
    )
};

const useStyles = makeStyles(theme => ({
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '1rem'
    }
}));

export default LinearLoading;