import React, {useContext, useEffect, useState} from 'react';
import {FirestoreContext} from "./FirestoreContextV2";
import {getMoment} from "../logic/timeHandler";

const DebugModeContext = React.createContext({});

let buffer = [];
const DebugModeContextProvider = props => {
    const data = useContext(FirestoreContext);

    const [debugMode, setDebugMode] = useState({
        debugMode: false,  // When on, debug UI is shown
        mockBooking: false,  // When on bookings are not written to db
        cliMode: false  // Gives access to data and functions in the console (under the alfalistCli namespace)
    });

    useEffect(() => {
        const listener = event => {
            if (event.key === 'Enter'){
                if (buffer.join('') === 'debug'){
                    console.log('%cDebug mode activated', 'color:green');
                    setDebugMode(prevState => ({...prevState, debugMode: true}));
                }

                if (buffer.join('') === 'mock'){
                    console.log('%cMock bookings activated. Bookings will not be written to database.', 'color:green');
                    setDebugMode(prevState => ({...prevState, mockBooking: true}));
                }

                if (buffer.join('') === 'cli'){
                    console.log('%cCommand line interface activated. Methods are found under the alfalistCli namespace', 'color:green');
                    setDebugMode(prevState => ({...prevState, cliMode: true}));
                }

                buffer = [];
            } else {
                buffer.push(event.key);
            }
        };

        document.addEventListener('keydown', listener);
        return () => {document.removeEventListener('keydown' , listener)};
    });

    useEffect(() => {
        if (!debugMode.cliMode){return;}

        window.alfalistCli = {
            printData: () => console.log(data),
            getMoment: getMoment
        };
    }, [debugMode.cliMode, data]);

    return(
        <DebugModeContext.Provider value={debugMode}>
            { props.children }
        </DebugModeContext.Provider>
    )
};

export { DebugModeContext, DebugModeContextProvider }