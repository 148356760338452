const localeTemplate = {
    localeName: '',  // Eg. "Norsk Bokmål"
    stepper: {
        services: '',
        time: '',
        customerInfo: '',
        confirmation: ''
    },
    providerSelect: {
        any: '',  // Eg. "Any (Most available)
        unnamedProvider: ''
    },
    services: {
        unnamedService: '',
        durationIntervals: {
            hour: '',
            hours: '',
            minute: '',
            minutes: ''
        }
    },
    time: {
        fullyBooked: '',
        venueOptionsDescription: {
            noAvailable: venueNames => ``,
            allAvailable: venueNames => ``,
            someAvailable: venueNames => ``,
            listAvailStatus: venuesAndStatus => {}
        },
        serviceName: serviceName => ``
    },
    customerInfo: {
        heading: '',
        nextButton: '',
        fields: {
            fullName: {
                label: '',
                placeholder: '',
                missingError: '',
                tooShortError: '',
                tooLongError: ''
            },
            firstName: {
                label: '',
                placeholder: '',
                missingError: '',
                tooShortError: '',
                tooLongError: ''
            },
            lastName: {
                label: '',
                placeholder: '',
                missingError: '',
                tooShortError: '',
                tooLongError: ''
            },
            email: {
                label: '',
                placeholder: '',
                missingError: '',
                formatError: '',
            },
            phone: {
                label: '',
                placeholder: '',
                missingError: '',
                tooShortError: '',
                tooLongError: ''
            },
            phoneCC: {
                label: '',
                placeholder: '',
                missingError: '',
                formatError: ''
            },
            address: {
                label: '',
                placeholder: '',
                missingError: '',
                tooShortError: '',
                tooLongError: ''
            },
            zip: {
                label: '',
                placeholder: '',
                missingError: '',
                tooShortError: '',
                tooLongError: ''
            },
            city: {
                label: '',
                placeholder: '',
                missingError: '',
                tooShortError: '',
                tooLongError: ''
            },
            country: {
                label: ''
            },
            notes: {
                label: '',
                placeholder: '',
                missingError: '',
                tooLongError: ''
            }
        }
    },
    confirmation: {
        heading: '',
        service: {
            name: '',
            price: '',
            provider: '',
            duration: ''
        },
        time: '',
        venue: {
            location: ''
        },
        customerInfo: {
            yourInformation: ''
        },
        confirmButton: '',
        consentCheckboxes: {
            bookingTermsCheckbox: '',
            promotionsCheckbox: '',
            newsletterCheckbox: '',
            bookingTermsLinkText: '',  // eg. "booking terms"
            bookingTermsError: '',  // Mandatory booking terms box not checked. Error displayed below confirm button
            consentNeededPrivacyPolicy: '',
            consentNeededPrivacyPolicyLinkText: '',
            bookingTermsNoCheckbox: '',  // Whole text, if no checkbox
            noMarketingBelow: ""
        },
        confirmationPending: '',  // Text below progress circle
        pressToConfirm: ''
    },
    thankYou: {
        heading: '',
        body: ''
    },
    legal: {
        tos: '',
        privacyPolicy: ''
    }
};

export default localeTemplate;