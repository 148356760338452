import searchDurationIntervals from "../searchDurationIntervals";
import {getFirestore} from "../../firebaseConfig";

/**
 * This function creates the necessary listeners to get all appointments in the selected range.
 * The callback is not fired until a result has been gathered for all the different listeners.
 * @param {string} businessId
 * @param {number} searchStartTs
 * @param {number} searchEndTs
 * @param {function} callback
 * @return {function} Cleanup function. Removes listeners.
 */
const createAptsListener = (businessId, searchStartTs, searchEndTs, callback) => {
    const firestore = getFirestore();
    if (!firestore){throw new Error('Attempting to run function createAptsListener before firestore is loaded.');}

    const resultsByIntervalKey = {};

    const handleResults = () => {
        if (listeners.some(l => !l.settled)){return;}  // If not all listeners have settled -> don't fire callback
        const arrayOfApts = Object.values(resultsByIntervalKey).reduce((acc, intervalObj) => acc.concat(Object.values(intervalObj)), []);
        // console.log("arrayOfApts: ", arrayOfApts);
        callback(arrayOfApts);
    };

    // Create listeners for apts
    const listeners = searchDurationIntervals.searchDurationIntervalKeys.map((intervalKey, index) => {
        let qry;
        if (intervalKey !== 'overflow'){
            qry = firestore.collection(`apts`).where(`searchEndTime.${intervalKey}`, ">=", searchStartTs).where(`searchEndTime.${intervalKey}`, "<=", searchEndTs + searchDurationIntervals.searchDurationIntervalsInSeconds[intervalKey]);
        } else {
            qry = firestore.collection(`apts`).where('search.overflow', "==", true);
        }

        qry = qry.where('business', '==', businessId)
            .where('cancelled', '==', false);

        const newListener = qry.onSnapshot(docs => {
            // Fulfill promise on first response
            if (!listeners[index].settled){
                listeners[index].settled = true;
            }

            // Store data
            resultsByIntervalKey[intervalKey] = {};
            docs.forEach(doc => {resultsByIntervalKey[intervalKey][doc.id] = doc.data();});
            handleResults();
        });

        return {listener: newListener, settled: false};
    });

    // Return detach function
    return () => {
        listeners.forEach(l => {l.listener();});
    }
};

export default createAptsListener;