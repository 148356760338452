import React, {useContext} from 'react';
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import {FirestoreContext} from "../../contexts/FirestoreContextV2";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {LocaleStringsContext} from "../../contexts/LocaleStringsContext";
import getTosUrl from "../../logic/confirmationFormatting/getTosUrl";
import getPrivacyPolicyUrl from "../../logic/confirmationFormatting/getPrivacyPolicyUrl";

const LegalFooter = props => {
    const locale = useContext(LocaleStringsContext);
    const data = useContext(FirestoreContext);
    const classes = useStyles();

    // Don't display before locale is loaded
    if (locale.legal.tos === ''){
        return null;
    }

    // For specific use cases where site is verified, manually set in db
    if (data.policies && data.policies.hideLegalFooter !== false){
        return null;
    }

    return(
        <div>
            <Typography className={classes.container}>
                <Link
                    color={"primary"}
                    variant={"inherit"}
                    href={getTosUrl(data.businessId)}
                    target={"_blank"}
                    rel="noopener"
                >
                    { locale.legal.tos }
                </Link>
                {" | "}
                <Link
                    color={"primary"}
                    variant={"inherit"}
                    href={getPrivacyPolicyUrl(data.businessId)}
                    target={"_blank"}
                    rel="noopener"
                >
                    { locale.legal.privacyPolicy }
                </Link>
            </Typography>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center',
        paddingTop: '5rem',
        [theme.breakpoints.up('lg')]: {
            paddingTop: '10rem'
        },
        marginBottom: '100px'  // = tolerance of iFrameResizer
    }
}));

export default LegalFooter;