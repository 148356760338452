/**
 * This function returns the correct url to retrieve media variants from GCS.
 * @param {Object} media - the userMedia document data
 * @param {string} prefix - eg. "25x25-Q80@1x"
 * @param {"webp"|"jpeg"} fileExtension
 * @return {string} - variant url
 */
const getMediaVariantUrl = (media, prefix, fileExtension) => {
    return `https://storage.googleapis.com/${media.bucket}/userMedia/${media.businessId}/${media.uploadedBy}/${media.fileId}/${prefix}.${fileExtension}`
};

export default getMediaVariantUrl;