import allCCs from '../phone/alpha2CodeByCallingCode';
import importFlag from "./importFlag";

const getFlagByCC = cc => {
    const uppercaseCC = cc.toUpperCase();

    return new Promise((resolve, reject) => {
        if (!Object.values(allCCs).includes(uppercaseCC)){console.error("Invalid CC", cc); reject();}

        importFlag(uppercaseCC).then(img => {
            // console.log("importFlag yielded: ", img);
            resolve(img.default);
        }).catch(() => {
            reject();
        });
    });
};

export default getFlagByCC;