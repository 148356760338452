import React, {createContext, useCallback} from 'react';

const TrackingContext = createContext({});

const TrackingContextProvider = ({ children }) => {
    const track = useCallback((arg1, arg2, arg3) => {
        try {
            if (typeof window === "undefined") {return;}  // Don't track on server
            // console.log("Tracking event: ", arg1, arg2, arg3);
            window.gtag(arg1, arg2, arg3);
        } catch (err){
            console.warn("Gtag error", err);
        }
    }, []);

    return(
        <TrackingContext.Provider value={track}>
            { children }
        </TrackingContext.Provider>
    )
};

export { TrackingContextProvider, TrackingContext };