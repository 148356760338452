import React, {useContext} from 'react';

// Core components
import Grid from '@material-ui/core/Grid';

import getIdsSortedByOrder from "../../../logic/getIdsSortedByOrder";
import CategoryCardV2 from "./CategoryCardV2";
import {FirestoreContext} from "../../../contexts/FirestoreContextV2";

const CardCategories = props => {
    const data = useContext(FirestoreContext);
    const categoryIds = getIdsSortedByOrder(data.categories);
    const serviceIds = getIdsSortedByOrder(data.services);

    return(
        <Grid container spacing={3}>
            {
                categoryIds.map(categoryId => {
                    const category = data.categories[categoryId];
                    const categoryServiceIds = serviceIds.filter(serviceId =>
                        data.services[serviceId].category === categoryId);

                    return(
                        <Grid item
                              key={categoryId}
                              xs={12}
                              sm={6}
                              lg={4}
                        >
                            <CategoryCardV2
                                category={category}
                                categoryId={categoryId}
                                open={props.categoriesOpen.includes(categoryId)}
                                toggleCategoryOpen={props.toggleCategoryOpen}
                                categoryServiceIds={categoryServiceIds}
                                services={data.services}
                                selectService={props.selectService}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
};

export default CardCategories;