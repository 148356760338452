import React, {useContext, useMemo} from 'react';

// Core components
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import getCountryNamesByLocale from "../../logic/country/getCountryNamesByLocale";
import {FirestoreContext} from "../../contexts/FirestoreContextV2";

const InputField = props => {
    const data = useContext(FirestoreContext);
    let passProps = props;

    const sortedCountries = useMemo(() => {
        if (!props.countrySelect){return null;}
        const countryNames = getCountryNamesByLocale(data.design.locale.strings);

        return Object.entries(countryNames)
            .sort((a, b) => a[1] - b[1])  // Sort countries alphabetically
    }, [props.countrySelect, data.design]);

    if (props.countrySelect){
        const children = sortedCountries.map(([cc, countryName]) => {
            return <MenuItem key={cc} value={cc}>{ countryName }</MenuItem>;
        });

        passProps = {
            ...passProps,
            select: true,
            children: children
        };
        delete passProps.countrySelect;
    }

    switch (props.template) {
        case "outlined":
            return <TextField variant={'outlined'} {...passProps}/>;
        default:
            console.error("Could not find component for InputField with template: ", props.template);
            return <TextField variant={'outlined'} {...passProps}/>;
    }
};

export default InputField;