import React, {useContext} from 'react';
import {LocaleStringsContext} from "../../../contexts/LocaleStringsContext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

// Container should have position: relative/absolute

const FullyBookedStamp = props => {
    const locale = useContext(LocaleStringsContext);
    const classes = useStyles();

    return(
        <div className={classes.wrapper}>
            <div className={classes.stamp}>
                <Typography className={classes.text}>{ locale.time.fullyBooked }</Typography>
            </div>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    stamp: {
        position: 'absolute',
        padding: '1.2rem',
        border: 'dashed 0.25rem red',
        left: '50%',
        top: '50%',
        transformOrigin: 'center',
        transform: 'translate(-50%, -60%) rotate(-25deg)',
        color: 'red',
        userSelect: 'none'
    },
    text: {
        fontSize: '1.2rem'
    }
}));

export default FullyBookedStamp;