import {getMoment} from "../timeHandler";

/**
 * @param data {Object} - firestore data
 * @param providerIds {Array<string>}
 * @param searchStartMoment {Object} moment object
 * @param searchEndMoment {Object} moment object
 * @param allAdjustments {Object}
 * @returns {Object}
 */
const calculateOpeningHoursForProviders = (
    data,
    providerIds,
    searchStartMoment,
    searchEndMoment,
    allAdjustments
) => {
    const moment = getMoment();
    if (!moment){throw new Error('Missing moment module');}

    const businessId = data.businessId;
    const allDefaultOpeningHours = data.defaultOpeningHours;

    const levels = ['provider', 'venue', 'business'];
    const allDefaultIds = Object.keys(allDefaultOpeningHours);
    const allAdjustmentIds = Object.keys(allAdjustments);

    // Sort the rules from highest to lowest priority
    const rulesOrderedByLevel = levels.map(level => {
        return allDefaultIds.filter(ruleId => {
            return allDefaultOpeningHours[ruleId].scope === level;
        }).map(ruleId => {
            return allDefaultOpeningHours[ruleId];
        });
    });

    // console.log('allDefaultIds', allDefaultIds);
    // console.log(allDefaultOpeningHours);
    // console.log('Rules ordered by level: ', rulesOrderedByLevel);

    // Find the applicable default rule for each provider
    const defaultRuleByProviderId = {};
    for (let providerId of providerIds) {
        let matchingRules;

        // Check for provider-level scope rule
        matchingRules = rulesOrderedByLevel[0].filter(rule => {
            return rule.providerId === providerId;
        });

        if (matchingRules.length > 0) { // There should always only be one matching entry here
            defaultRuleByProviderId[providerId] = matchingRules[0];
            continue;
        }

        // Check for matching venue-level rules
        matchingRules = rulesOrderedByLevel[1].filter(rule => {
            return rule.venueId === data.providers[providerId].venue;
        });

        if (matchingRules.length > 0) { // There should always only be one matching entry here
            defaultRuleByProviderId[providerId] = matchingRules[0];
            continue;
        }

        // Check for matching business-level rules
        matchingRules = rulesOrderedByLevel[2].filter(rule => {
            return rule.business === businessId;
        });

        if (matchingRules.length > 0) { // There should always only be one matching entry here
            defaultRuleByProviderId[providerId] = matchingRules[0];
            continue;
        }

        // No applicable rules are found, use global default

        // Create global default data
        const globalDefaultData = [];
        for (let i = 0; i < 5; i++) {
            globalDefaultData.push({start: "09:00", end: "17:00"});
        } // Nine to five on weekdays
        for (let i = 0; i < 2; i++) {
            globalDefaultData.push({start: "12:00", end: "12:00"});
        } // Weekends off

        defaultRuleByProviderId[providerId] = {
            business: businessId,
            scope: 'globalDefault',
            data: globalDefaultData
        };
    }

    // Apply adjustments
    const result = {};
    const currentMoment = searchStartMoment.clone();
    currentMoment.startOf('day');
    const endMoment = searchEndMoment.clone();

    while (currentMoment.isSameOrBefore(endMoment)){
        const dateStr = currentMoment.format('YYYYMMDD').toString();

        // Find the day of week and make it 0-indexed. (locale unaware. Needs to match database)
        const dayOfWeek = currentMoment.isoWeekday() - 1;

        providerIds.forEach(providerId => {
            if (!result[providerId]){result[providerId] = [];}

            ['start', 'end'].forEach(startOrEnd => {
                const startAdjIdMatches = allAdjustmentIds
                    .filter(adjId => {
                        const adj = allAdjustments[adjId];
                        return(
                            adj.date.toString() === dateStr &&
                            adj.providerId === providerId &&
                            adj.startOrEnd === startOrEnd
                        );
                    }).map(matchId => {
                        return allAdjustments[matchId];
                    });

                if (startAdjIdMatches.length > 0){  // An adjustment to the start time was found -> push this
                    const adj = startAdjIdMatches[0];  // There should never be more than one adjustment

                    if (startOrEnd === 'start'){
                        const dayStart = currentMoment.clone();
                        dayStart.startOf('day');

                        result[providerId].push({
                            startOrEnd: startOrEnd,
                            tsStart: dayStart.unix(),
                            tsEnd: adj.adjustToTimestamp,
                            dateStr: dateStr,
                            tentativeProvider: providerId,
                            type: 'adjustment'
                        });
                    } else {
                        const dayEnd = currentMoment.clone();
                        dayEnd.endOf('day');

                        result[providerId].push({
                            startOrEnd: startOrEnd,
                            tsStart: adj.adjustToTimestamp,
                            tsEnd: dayEnd.unix(),
                            dateStr: dateStr,
                            tentativeProvider: providerId,
                            type: 'adjustment'
                        });
                    }
                } else {  // No adjustment was found -> push default
                    const adjustTo = currentMoment.clone();
                    const [hh, mm] = defaultRuleByProviderId[providerId]
                        .data[dayOfWeek][startOrEnd].split(':');
                    adjustTo.hours(hh);
                    adjustTo.minutes(mm);

                    if (startOrEnd === 'start'){
                        const dayStart = currentMoment.clone();
                        dayStart.startOf('day');

                        result[providerId].push({
                            startOrEnd: startOrEnd,
                            tsStart: dayStart.unix(),
                            tsEnd: adjustTo.unix(),
                            dateStr: dateStr,
                            tentativeProvider: providerId,
                            type: 'default_' + defaultRuleByProviderId[providerId].scope
                        });
                    } else {
                        const dayEnd = currentMoment.clone();
                        dayEnd.endOf('day');

                        result[providerId].push({
                            startOrEnd: startOrEnd,
                            tsStart: adjustTo.unix(),
                            tsEnd: dayEnd.unix(),
                            dateStr: dateStr,
                            tentativeProvider: providerId,
                            type: 'default_' + defaultRuleByProviderId[providerId].scope
                        });
                    }
                }
            });
        });

        currentMoment.add(1, 'days');
    }

    return result;
};

export default calculateOpeningHoursForProviders;