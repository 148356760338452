/**
 *
 * @param {string} serviceId
 * @param {string|null} venueId - if null, match all venues
 * @param {Object} data - FirestoreContext data
 * @returns {Array<string>} Array of providerIds
 */
import getPublicBookingForProvider from "./getPublicBookingForProvider";

const getEligibleProviderIds = (serviceId, venueId = null, data) => {
    return Object.keys(data.providers).filter(providerId => {
        // Provider hidden
        if (data.providers[providerId].hidden){return false;}

        // Service hidden
        if (data.services[serviceId].hidden){return false;}

        // Venue is hidden
        if (data.venues[data.providers[providerId].venue].hidden){return false;}

        // Venue not relevant
        if (venueId !== null && data.providers[providerId].venue !== venueId){return false;}

        // Provider does not offer service
        if (!getPublicBookingForProvider(serviceId, providerId, data)){return false;}

        return true;
    });
};

export default getEligibleProviderIds;