const importFlag = cc => {
    let prm;
    switch(cc){
        case "US":
            prm = import("../../../graphics/flags/us.svg");
            break;
        case "RU":
            prm = import("../../../graphics/flags/ru.svg");
            break;
        case "EG":
            prm = import("../../../graphics/flags/eg.svg");
            break;
        case "ZA":
            prm = import("../../../graphics/flags/za.svg");
            break;
        case "GR":
            prm = import("../../../graphics/flags/gr.svg");
            break;
        case "NL":
            prm = import("../../../graphics/flags/nl.svg");
            break;
        case "BE":
            prm = import("../../../graphics/flags/be.svg");
            break;
        case "FR":
            prm = import("../../../graphics/flags/fr.svg");
            break;
        case "ES":
            prm = import("../../../graphics/flags/es.svg");
            break;
        case "HU":
            prm = import("../../../graphics/flags/hu.svg");
            break;
        case "IT":
            prm = import("../../../graphics/flags/it.svg");
            break;
        case "RO":
            prm = import("../../../graphics/flags/ro.svg");
            break;
        case "CH":
            prm = import("../../../graphics/flags/ch.svg");
            break;
        case "AT":
            prm = import("../../../graphics/flags/at.svg");
            break;
        case "GB":
            prm = import("../../../graphics/flags/gb.svg");
            break;
        case "DK":
            prm = import("../../../graphics/flags/dk.svg");
            break;
        case "SE":
            prm = import("../../../graphics/flags/se.svg");
            break;
        case "NO":
            prm = import("../../../graphics/flags/no.svg");
            break;
        case "PL":
            prm = import("../../../graphics/flags/pl.svg");
            break;
        case "DE":
            prm = import("../../../graphics/flags/de.svg");
            break;
        case "PE":
            prm = import("../../../graphics/flags/pe.svg");
            break;
        case "MX":
            prm = import("../../../graphics/flags/mx.svg");
            break;
        case "CU":
            prm = import("../../../graphics/flags/cu.svg");
            break;
        case "AR":
            prm = import("../../../graphics/flags/ar.svg");
            break;
        case "BR":
            prm = import("../../../graphics/flags/br.svg");
            break;
        case "CL":
            prm = import("../../../graphics/flags/cl.svg");
            break;
        case "CO":
            prm = import("../../../graphics/flags/co.svg");
            break;
        case "VE":
            prm = import("../../../graphics/flags/ve.svg");
            break;
        case "MY":
            prm = import("../../../graphics/flags/my.svg");
            break;
        case "CC":
            prm = import("../../../graphics/flags/cc.svg");
            break;
        case "ID":
            prm = import("../../../graphics/flags/id.svg");
            break;
        case "PH":
            prm = import("../../../graphics/flags/ph.svg");
            break;
        case "PN":
            prm = import("../../../graphics/flags/pn.svg");
            break;
        case "SG":
            prm = import("../../../graphics/flags/sg.svg");
            break;
        case "TH":
            prm = import("../../../graphics/flags/th.svg");
            break;
        case "KZ":
            prm = import("../../../graphics/flags/kz.svg");
            break;
        case "JP":
            prm = import("../../../graphics/flags/jp.svg");
            break;
        case "KR":
            prm = import("../../../graphics/flags/kr.svg");
            break;
        case "VN":
            prm = import("../../../graphics/flags/vn.svg");
            break;
        case "CN":
            prm = import("../../../graphics/flags/cn.svg");
            break;
        case "TR":
            prm = import("../../../graphics/flags/tr.svg");
            break;
        case "IN":
            prm = import("../../../graphics/flags/in.svg");
            break;
        case "PK":
            prm = import("../../../graphics/flags/pk.svg");
            break;
        case "AF":
            prm = import("../../../graphics/flags/af.svg");
            break;
        case "LK":
            prm = import("../../../graphics/flags/lk.svg");
            break;
        case "MM":
            prm = import("../../../graphics/flags/mm.svg");
            break;
        case "IR":
            prm = import("../../../graphics/flags/ir.svg");
            break;
        case "SS":
            prm = import("../../../graphics/flags/ss.svg");
            break;
        case "EH":
            prm = import("../../../graphics/flags/eh.svg");
            break;
        case "DZ":
            prm = import("../../../graphics/flags/dz.svg");
            break;
        case "TN":
            prm = import("../../../graphics/flags/tn.svg");
            break;
        case "LY":
            prm = import("../../../graphics/flags/ly.svg");
            break;
        case "GM":
            prm = import("../../../graphics/flags/gm.svg");
            break;
        case "SN":
            prm = import("../../../graphics/flags/sn.svg");
            break;
        case "MR":
            prm = import("../../../graphics/flags/mr.svg");
            break;
        case "ML":
            prm = import("../../../graphics/flags/ml.svg");
            break;
        case "GN":
            prm = import("../../../graphics/flags/gn.svg");
            break;
        case "CI":
            prm = import("../../../graphics/flags/ci.svg");
            break;
        case "BF":
            prm = import("../../../graphics/flags/bf.svg");
            break;
        case "NE":
            prm = import("../../../graphics/flags/ne.svg");
            break;
        case "TG":
            prm = import("../../../graphics/flags/tg.svg");
            break;
        case "BJ":
            prm = import("../../../graphics/flags/bj.svg");
            break;
        case "MU":
            prm = import("../../../graphics/flags/mu.svg");
            break;
        case "LR":
            prm = import("../../../graphics/flags/lr.svg");
            break;
        case "SL":
            prm = import("../../../graphics/flags/sl.svg");
            break;
        case "GH":
            prm = import("../../../graphics/flags/gh.svg");
            break;
        case "NG":
            prm = import("../../../graphics/flags/ng.svg");
            break;
        case "TD":
            prm = import("../../../graphics/flags/td.svg");
            break;
        case "CF":
            prm = import("../../../graphics/flags/cf.svg");
            break;
        case "CM":
            prm = import("../../../graphics/flags/cm.svg");
            break;
        case "CV":
            prm = import("../../../graphics/flags/cv.svg");
            break;
        case "ST":
            prm = import("../../../graphics/flags/st.svg");
            break;
        case "GQ":
            prm = import("../../../graphics/flags/gq.svg");
            break;
        case "GA":
            prm = import("../../../graphics/flags/ga.svg");
            break;
        case "CG":
            prm = import("../../../graphics/flags/cg.svg");
            break;
        case "CD":
            prm = import("../../../graphics/flags/cd.svg");
            break;
        case "AO":
            prm = import("../../../graphics/flags/ao.svg");
            break;
        case "GW":
            prm = import("../../../graphics/flags/gw.svg");
            break;
        case "IO":
            prm = import("../../../graphics/flags/io.svg");
            break;
        case "SC":
            prm = import("../../../graphics/flags/sc.svg");
            break;
        case "SD":
            prm = import("../../../graphics/flags/sd.svg");
            break;
        case "RW":
            prm = import("../../../graphics/flags/rw.svg");
            break;
        case "ET":
            prm = import("../../../graphics/flags/et.svg");
            break;
        case "SO":
            prm = import("../../../graphics/flags/so.svg");
            break;
        case "DJ":
            prm = import("../../../graphics/flags/dj.svg");
            break;
        case "KE":
            prm = import("../../../graphics/flags/ke.svg");
            break;
        case "TZ":
            prm = import("../../../graphics/flags/tz.svg");
            break;
        case "UG":
            prm = import("../../../graphics/flags/ug.svg");
            break;
        case "BI":
            prm = import("../../../graphics/flags/bi.svg");
            break;
        case "MZ":
            prm = import("../../../graphics/flags/mz.svg");
            break;
        case "ZM":
            prm = import("../../../graphics/flags/zm.svg");
            break;
        case "MG":
            prm = import("../../../graphics/flags/mg.svg");
            break;
        case "RE":
            prm = import("../../../graphics/flags/re.svg");
            break;
        case "ZW":
            prm = import("../../../graphics/flags/zw.svg");
            break;
        case "NA":
            prm = import("../../../graphics/flags/na.svg");
            break;
        case "MW":
            prm = import("../../../graphics/flags/mw.svg");
            break;
        case "LS":
            prm = import("../../../graphics/flags/ls.svg");
            break;
        case "BW":
            prm = import("../../../graphics/flags/bw.svg");
            break;
        case "SZ":
            prm = import("../../../graphics/flags/sz.svg");
            break;
        case "KM":
            prm = import("../../../graphics/flags/km.svg");
            break;
        case "SH":
            prm = import("../../../graphics/flags/sh.svg");
            break;
        case "ER":
            prm = import("../../../graphics/flags/er.svg");
            break;
        case "AW":
            prm = import("../../../graphics/flags/aw.svg");
            break;
        case "FO":
            prm = import("../../../graphics/flags/fo.svg");
            break;
        case "GL":
            prm = import("../../../graphics/flags/gl.svg");
            break;
        case "GI":
            prm = import("../../../graphics/flags/gi.svg");
            break;
        case "PT":
            prm = import("../../../graphics/flags/pt.svg");
            break;
        case "LU":
            prm = import("../../../graphics/flags/lu.svg");
            break;
        case "IE":
            prm = import("../../../graphics/flags/ie.svg");
            break;
        case "IS":
            prm = import("../../../graphics/flags/is.svg");
            break;
        case "AL":
            prm = import("../../../graphics/flags/al.svg");
            break;
        case "MT":
            prm = import("../../../graphics/flags/mt.svg");
            break;
        case "CY":
            prm = import("../../../graphics/flags/cy.svg");
            break;
        case "FI":
            prm = import("../../../graphics/flags/fi.svg");
            break;
        case "BG":
            prm = import("../../../graphics/flags/bg.svg");
            break;
        case "LT":
            prm = import("../../../graphics/flags/lt.svg");
            break;
        case "LV":
            prm = import("../../../graphics/flags/lv.svg");
            break;
        case "EE":
            prm = import("../../../graphics/flags/ee.svg");
            break;
        case "MD":
            prm = import("../../../graphics/flags/md.svg");
            break;
        case "AM":
            prm = import("../../../graphics/flags/am.svg");
            break;
        case "BY":
            prm = import("../../../graphics/flags/by.svg");
            break;
        case "AD":
            prm = import("../../../graphics/flags/ad.svg");
            break;
        case "MC":
            prm = import("../../../graphics/flags/mc.svg");
            break;
        case "SM":
            prm = import("../../../graphics/flags/sm.svg");
            break;
        case "VA":
            prm = import("../../../graphics/flags/va.svg");
            break;
        case "UA":
            prm = import("../../../graphics/flags/ua.svg");
            break;
        case "RS":
            prm = import("../../../graphics/flags/rs.svg");
            break;
        case "ME":
            prm = import("../../../graphics/flags/me.svg");
            break;
        case "XK":
            prm = import("../../../graphics/flags/xk.svg");
            break;
        case "HR":
            prm = import("../../../graphics/flags/hr.svg");
            break;
        case "SI":
            prm = import("../../../graphics/flags/si.svg");
            break;
        case "BA":
            prm = import("../../../graphics/flags/ba.svg");
            break;
        case "MK":
            prm = import("../../../graphics/flags/mk.svg");
            break;
        case "CZ":
            prm = import("../../../graphics/flags/cz.svg");
            break;
        case "SK":
            prm = import("../../../graphics/flags/sk.svg");
            break;
        case "LI":
            prm = import("../../../graphics/flags/li.svg");
            break;
        case "GS":
            prm = import("../../../graphics/flags/gs.svg");
            break;
        case "BZ":
            prm = import("../../../graphics/flags/bz.svg");
            break;
        case "GT":
            prm = import("../../../graphics/flags/gt.svg");
            break;
        case "SV":
            prm = import("../../../graphics/flags/sv.svg");
            break;
        case "HN":
            prm = import("../../../graphics/flags/hn.svg");
            break;
        case "NI":
            prm = import("../../../graphics/flags/ni.svg");
            break;
        case "CR":
            prm = import("../../../graphics/flags/cr.svg");
            break;
        case "PA":
            prm = import("../../../graphics/flags/pa.svg");
            break;
        case "PM":
            prm = import("../../../graphics/flags/pm.svg");
            break;
        case "HT":
            prm = import("../../../graphics/flags/ht.svg");
            break;
        case "MF":
            prm = import("../../../graphics/flags/mf.svg");
            break;
        case "BO":
            prm = import("../../../graphics/flags/bo.svg");
            break;
        case "GY":
            prm = import("../../../graphics/flags/gy.svg");
            break;
        case "EC":
            prm = import("../../../graphics/flags/ec.svg");
            break;
        case "GF":
            prm = import("../../../graphics/flags/gf.svg");
            break;
        case "PY":
            prm = import("../../../graphics/flags/py.svg");
            break;
        case "MQ":
            prm = import("../../../graphics/flags/mq.svg");
            break;
        case "SR":
            prm = import("../../../graphics/flags/sr.svg");
            break;
        case "UY":
            prm = import("../../../graphics/flags/uy.svg");
            break;
        case "CW":
            prm = import("../../../graphics/flags/cw.svg");
            break;
        case "TL":
            prm = import("../../../graphics/flags/tl.svg");
            break;
        case "NF":
            prm = import("../../../graphics/flags/nf.svg");
            break;
        case "BN":
            prm = import("../../../graphics/flags/bn.svg");
            break;
        case "NR":
            prm = import("../../../graphics/flags/nr.svg");
            break;
        case "PG":
            prm = import("../../../graphics/flags/pg.svg");
            break;
        case "TO":
            prm = import("../../../graphics/flags/to.svg");
            break;
        case "SB":
            prm = import("../../../graphics/flags/sb.svg");
            break;
        case "VU":
            prm = import("../../../graphics/flags/vu.svg");
            break;
        case "FJ":
            prm = import("../../../graphics/flags/fj.svg");
            break;
        case "PW":
            prm = import("../../../graphics/flags/pw.svg");
            break;
        case "WF":
            prm = import("../../../graphics/flags/wf.svg");
            break;
        case "CK":
            prm = import("../../../graphics/flags/ck.svg");
            break;
        case "NU":
            prm = import("../../../graphics/flags/nu.svg");
            break;
        case "WS":
            prm = import("../../../graphics/flags/ws.svg");
            break;
        case "KI":
            prm = import("../../../graphics/flags/ki.svg");
            break;
        case "NC":
            prm = import("../../../graphics/flags/nc.svg");
            break;
        case "TV":
            prm = import("../../../graphics/flags/tv.svg");
            break;
        case "PF":
            prm = import("../../../graphics/flags/pf.svg");
            break;
        case "TK":
            prm = import("../../../graphics/flags/tk.svg");
            break;
        case "FM":
            prm = import("../../../graphics/flags/fm.svg");
            break;
        case "MH":
            prm = import("../../../graphics/flags/mh.svg");
            break;
        case "KP":
            prm = import("../../../graphics/flags/kp.svg");
            break;
        case "HK":
            prm = import("../../../graphics/flags/hk.svg");
            break;
        case "MO":
            prm = import("../../../graphics/flags/mo.svg");
            break;
        case "KH":
            prm = import("../../../graphics/flags/kh.svg");
            break;
        case "LA":
            prm = import("../../../graphics/flags/la.svg");
            break;
        case "BD":
            prm = import("../../../graphics/flags/bd.svg");
            break;
        case "TW":
            prm = import("../../../graphics/flags/tw.svg");
            break;
        case "MV":
            prm = import("../../../graphics/flags/mv.svg");
            break;
        case "LB":
            prm = import("../../../graphics/flags/lb.svg");
            break;
        case "JO":
            prm = import("../../../graphics/flags/jo.svg");
            break;
        case "SY":
            prm = import("../../../graphics/flags/sy.svg");
            break;
        case "IQ":
            prm = import("../../../graphics/flags/iq.svg");
            break;
        case "KW":
            prm = import("../../../graphics/flags/kw.svg");
            break;
        case "SA":
            prm = import("../../../graphics/flags/sa.svg");
            break;
        case "YE":
            prm = import("../../../graphics/flags/ye.svg");
            break;
        case "OM":
            prm = import("../../../graphics/flags/om.svg");
            break;
        case "PS":
            prm = import("../../../graphics/flags/ps.svg");
            break;
        case "AE":
            prm = import("../../../graphics/flags/ae.svg");
            break;
        case "IL":
            prm = import("../../../graphics/flags/il.svg");
            break;
        case "BH":
            prm = import("../../../graphics/flags/bh.svg");
            break;
        case "QA":
            prm = import("../../../graphics/flags/qa.svg");
            break;
        case "BT":
            prm = import("../../../graphics/flags/bt.svg");
            break;
        case "MN":
            prm = import("../../../graphics/flags/mn.svg");
            break;
        case "NP":
            prm = import("../../../graphics/flags/np.svg");
            break;
        case "TJ":
            prm = import("../../../graphics/flags/tj.svg");
            break;
        case "TM":
            prm = import("../../../graphics/flags/tm.svg");
            break;
        case "AZ":
            prm = import("../../../graphics/flags/az.svg");
            break;
        case "GE":
            prm = import("../../../graphics/flags/ge.svg");
            break;
        case "KG":
            prm = import("../../../graphics/flags/kg.svg");
            break;
        case "UZ":
            prm = import("../../../graphics/flags/uz.svg");
            break;
        case "BS":
            prm = import("../../../graphics/flags/bs.svg");
            break;
        case "BB":
            prm = import("../../../graphics/flags/bb.svg");
            break;
        case "AI":
            prm = import("../../../graphics/flags/ai.svg");
            break;
        case "AG":
            prm = import("../../../graphics/flags/ag.svg");
            break;
        case "VG":
            prm = import("../../../graphics/flags/vg.svg");
            break;
        case "VI":
            prm = import("../../../graphics/flags/vi.svg");
            break;
        case "KY":
            prm = import("../../../graphics/flags/ky.svg");
            break;
        case "BM":
            prm = import("../../../graphics/flags/bm.svg");
            break;
        case "GD":
            prm = import("../../../graphics/flags/gd.svg");
            break;
        case "TC":
            prm = import("../../../graphics/flags/tc.svg");
            break;
        case "MS":
            prm = import("../../../graphics/flags/ms.svg");
            break;
        case "MP":
            prm = import("../../../graphics/flags/mp.svg");
            break;
        case "GU":
            prm = import("../../../graphics/flags/gu.svg");
            break;
        case "AS":
            prm = import("../../../graphics/flags/as.svg");
            break;
        case "SX":
            prm = import("../../../graphics/flags/sx.svg");
            break;
        case "LC":
            prm = import("../../../graphics/flags/lc.svg");
            break;
        case "DM":
            prm = import("../../../graphics/flags/dm.svg");
            break;
        case "VC":
            prm = import("../../../graphics/flags/vc.svg");
            break;
        case "PR":
            prm = import("../../../graphics/flags/pr.svg");
            break;
        case "DO":
            prm = import("../../../graphics/flags/do.svg");
            break;
        case "TT":
            prm = import("../../../graphics/flags/tt.svg");
            break;
        case "KN":
            prm = import("../../../graphics/flags/kn.svg");
            break;
        case "JM":
            prm = import("../../../graphics/flags/jm.svg");
            break;
        case "SJ":
            prm = import("../../../graphics/flags/sj.svg");
            break;
        case "BQ":
            prm = import("../../../graphics/flags/bq.svg");
            break;
        case "HM":
            prm = import("../../../graphics/flags/hm.svg");
            break;
        default:
            console.error("Could not find flag", cc);
            throw new Error('Missing flag');
    }

    return prm;
};

export default importFlag;