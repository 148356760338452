import React, {useCallback, useContext, useMemo, useState} from 'react';

import SimpleProviderPicker from "../../ProviderPicker/SimpleProviderPicker";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import {FirestoreContext} from "../../../../contexts/FirestoreContextV2";
import getPickableSlots from "../../../../logic/calendar/getPickableSlots";
import Typography from "@material-ui/core/Typography";
import getEligibleProviderIds from "../../../../logic/getEligibleProviderIds";
import FullyBookedStamp from "../../FullyBookedIndicators/FullyBookedStamp";
import LinearLoading from "../../../../components/loading/LinearLoading";
import ServiceAlternatives from "../../ServiceAlternative/ServiceAlternatives";
import {ErrorReportingContext} from "../../../../contexts/ErrorBoundary";
import {LocaleStringsContext} from "../../../../contexts/LocaleStringsContext";
import {TrackingContext} from "../../../../contexts/TrackingContextV3";

export default function(props){
    const [providerFilter, setProviderFilter] = useState('any');
    const classes = useStyles();

    const data = useContext(FirestoreContext);
    const ErrorContext = useContext(ErrorReportingContext);
    const locale = useContext(LocaleStringsContext);
    const track = useContext(TrackingContext);

    const { selectTime, venueId } = props;
    const handleSelectTime = useCallback(aptStartMoment => {
        selectTime(aptStartMoment.clone(), providerFilter, props.venueId);
    }, [selectTime, providerFilter, venueId]);

    const eligibleProviders = getEligibleProviderIds(props.selectedServiceId, props.venueId, data);
    if (eligibleProviders.length === 0){
        ErrorContext.nonBreakingError(new Error('SimpleTimePickerVenue, unexpected no eligible providers.'));
        return null;
    }

    let boxGridHeightInRem = 15;

    const { availSlots, selectedServiceId, setSelection, startOfDayTs, endOfDayTs, selectedDate } = props;
    const [content, loadingBar] = useMemo(() => {
        // No date selected yet
        if (!selectedDate){
            return([null, null]);
        }

        // Still loading
        if (!availSlots){
            return([
                null,
                <LinearLoading/>
            ]);
        }

        // Get providers relevant for the display boxes
        const relevantProviders = providerFilter === 'any' ? eligibleProviders : [providerFilter];
        const pickableSlots = getPickableSlots(availSlots, selectedServiceId, relevantProviders, data)[props.venueId] || [];

        // Set the height of the container
        const boxGridHeightInSlots = Math.max(5, Math.ceil(pickableSlots.length / 3));
        boxGridHeightInRem = (3 * boxGridHeightInSlots);

        if (pickableSlots.length > 0){
            return([
                pickableSlots.map(slot => {
                    return(
                        <div key={slot.slotStartTs.toString()} className={classes.box} onClick={() => handleSelectTime(slot.slotStartMoment)}>
                            <Typography className={classes.boxText}>{ slot.slotStartMoment.format('HH:mm')}</Typography>
                        </div>
                    );
                }),
                null
            ]);
        } else {
            track("event", "fully_booked_stamp_shown", {
                service_id: selectedServiceId,
                venue_id: venueId
            });

            return([
                <div className={classes.fullyBookedWrapper}>
                    <div className={classes.fullyBookedStampGridItem}>
                        <FullyBookedStamp/>
                    </div>
                    <div>
                        <ServiceAlternatives
                            selectedServiceId={selectedServiceId}
                            setSelection={setSelection}
                            setProviderFilter={setProviderFilter}
                            startOfDayTs={startOfDayTs}
                            endOfDayTs={endOfDayTs}
                            venueId={venueId}
                        />
                    </div>
                </div>,
                null
            ]);
        }
    }, [availSlots, loadingBar, selectedServiceId, providerFilter, setSelection, startOfDayTs, endOfDayTs, venueId, track]);

    // We hide the component if no date has been picked yet
    if (!selectedDate){
        return null;
    }

    return(
        <Paper className={classes.paper}>
            <SimpleProviderPicker
                venueId={props.venueId}
                onChange={setProviderFilter}
                providerFilter={providerFilter}
                options={eligibleProviders}
            />
            <div>
                <Typography component={"div"}
                            variant={"caption"}
                            className={classes.serviceName}
                >
                    { locale.time.serviceName(data.services[props.selectedServiceId].name) }
                </Typography>
            </div>
            <div className={classes.boxContainer}>
                <div className={classes.boxGrid} style={{height: boxGridHeightInRem.toString() + 'rem'}}>
                    { content }
                </div>
            </div>
            { loadingBar }
        </Paper>
    )
}

const useStyles = makeStyles(theme => ({
    boxContainer: {
        padding: '0 1rem 1rem 1rem'
    },
    paper: {
        backgroundColor: 'rgba(0,0,0,0.03)',
        position: 'relative'
    },
    boxGrid: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        position: 'relative' // Used to center fully booked stamp
    },
    box: {
        // padding: '0.7rem 1rem',
        width: 'calc(33% - 0.6rem)',
        height: '2.4rem',
        margin: '0.3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        boxSizing: 'border-box',
        border: '1px solid rgba(0,0,0,0.3)',
        color: 'rgba(0,0,0,0.7)',
        cursor: 'pointer',
        '&:hover': {
            border: '1px solid rgba(0,0,0,1)',
            color: 'black'
        }
    },
    boxText: {
        fontSize: '1rem',
        lineHeight: '1rem'
    },
    fullyBookedWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        '&>div': {
            width: '100%'
        },
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    fullyBookedStampGridItem: {
        flexGrow: '1',
        position: 'relative'
    },
    serviceName: {
        textAlign: 'center'
    }
}));