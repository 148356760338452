import React, { useContext } from 'react';
import {FirestoreContext} from "../../../contexts/FirestoreContextV2";
import getPickableSlots from "../../../logic/calendar/getPickableSlots";
import makeStyles from "@material-ui/core/styles/makeStyles";

const SlotView = props => {
    const data = useContext(FirestoreContext);
    const classes = useStyles();
    const availSlots = props.availSlots;

    if (!availSlots){
        return(<div style={{width: '100%'}}>No avail slots prop.</div>);
    }

    const providerIds = Object.keys(data.providers);

    return Object.keys(data.venues).map(venueId => {
        const slotsForThisVenue = availSlots[venueId];
        if (!slotsForThisVenue){
            return <div key={venueId}>Venue registered as irrelevant: {data.venues[venueId].name}</div>
        }

        return(
            <div key={venueId}>
                <div>Venue: {data.venues[venueId].name}</div>
                <table>
                    <thead>
                    <tr><td>Slot time</td>
                        {
                            providerIds.map(providerId => {
                                return(<td key={venueId + '_' + providerId}>{ data.providers[providerId].name }</td>)
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        slotsForThisVenue.map(slot => {
                            return(
                                <tr key={venueId + '_' + slot.slotStartTs}>
                                    <td className={classes.slot}
                                        onClick={() => props.selectTime(slot.slotStartMoment, "any", venueId)}
                                    >
                                        { slot.slotStartMoment.format('YYYY-MM-DD HH:mm') }
                                    </td>
                                    { providerIds.map(providerId => {
                                        let pickableSlots = [];
                                        const service = data.services[props.selectedServiceId];
                                        if (
                                            service.providers.hasOwnProperty(providerId) &&
                                            service.providers[providerId].publicBooking
                                        ){
                                            pickableSlots = getPickableSlots(availSlots, props.selectedServiceId, [providerId], data);
                                        }

                                        let backgroundColor, txt;
                                        if (!slot.availableByProviderId.hasOwnProperty(providerId)){
                                            backgroundColor = 'grey';
                                            txt = '';
                                        } else if (
                                            slot.availableByProviderId[providerId] === true &&
                                            pickableSlots[venueId] &&
                                            pickableSlots[venueId].includes(slot)
                                        ){
                                            backgroundColor = 'green';
                                            txt = '';
                                        } else if (slot.availableByProviderId[providerId] === true){
                                            backgroundColor = 'yellow';
                                            txt = '';
                                        } else if (slot.availableByProviderId[providerId] === false){
                                            backgroundColor = 'red';
                                            txt = '';
                                        } else {
                                            backgroundColor = 'orange';
                                            txt = 'Unexpected value';
                                        }

                                        return(
                                            <td key={venueId + '_' + providerId + '' + slot.slotStartTs}
                                                className={classes.slot}
                                                style={{backgroundColor: backgroundColor}}
                                                onClick={() => {props.selectTime(slot.slotStartMoment, providerId, venueId)}}
                                            >
                                                {txt}
                                            </td>)
                                    })}
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    });
};

const useStyles = makeStyles(theme => ({
    slot: {
        minWidth: '4rem',
        cursor: 'pointer',
        border: '1px solid white',
        '&:hover': {
            border: '1px solid black'
        }
    }
}));

export default SlotView;