import React, {useContext, useMemo} from 'react';

import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createTheme from "@material-ui/core/styles/createTheme";
import merge from 'lodash/merge';
import * as colors from '@material-ui/core/colors';
import {FirestoreContext} from "./FirestoreContextV2";

const theme = themeOverrides => {
    const options = {
        palette: {
            text: {
                tonedDown: 'rgba(0,0,0,0.6)'
            },
            example: {
                background: '#fff6df',
                border: '#e5e1b7'
            }
        },
        typography: {
            fontFamily: [
                'Open Sans',
                'sans-serif'
            ],
            h4: {
                color: colors.grey[500],
                textAlign: 'center',
                marginBottom: '1rem'
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,  // down from 750
                md: 650,  // down from 850
                lg: 850,  // down from 1125
                xl: 950  // down from 1920
            }
        },
        services: {
            cardCategories: {
                backgroundColor: colors.grey['100']
            }
        }
    };

    const res = merge(options, themeOverrides || {});

    return createTheme(res);
};

const Theme = props => {
    const data = useContext(FirestoreContext);

    const generatedTheme = useMemo(() => {
        let themeOverrides = {};

        if (
            data.design &&
            data.design.theme
        ){
            const t = data.design.theme;
            themeOverrides = {
                palette: {
                    primary: colors[t.palette.primary],
                    secondary: colors[t.palette.secondary]
                }
            };
        }

        return theme(themeOverrides)
    }, [data.design]);

    return(
        <ThemeProvider theme={generatedTheme}>
            { props.children }
        </ThemeProvider>
    )
};


// class Theme extends React.Component{
//     shouldComponentUpdate(nextProps, nextState, nextContext) {
//         return !isEqual(this.props.data.design.theme, nextProps.data.design.theme);
//     }
//
//     render(){
//         const t = this.props.data.design.theme;
//         const themeOverrides = {
//             palette: {
//                 primary: colors[t.palette.primary],
//                 secondary: colors[t.palette.secondary]
//             }
//         };
//
//         return(
//             <ThemeProvider theme={theme(themeOverrides)}>
//                 { this.props.children }
//             </ThemeProvider>
//         )
//     }
// }

export default Theme;