/*
* Version 2 does not animate the height to increase performace.
* Instead the bottom part of the card is hidden below the top part
* and slides out from underneath.
* */

import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import ResponsivePicture from "../../../components/images/ResponsivePicture";
import {FirestoreContext} from "../../../contexts/FirestoreContextV2";
import usePixelDimensions from "../../../customHooks/usePixelDimensions";
import GalleryRowForCategory from "../extras/GalleryRow/GalleryRowForCategory";

// Core components
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from "@material-ui/core/ListItemText";
import Grid from '@material-ui/core/Grid';
import makeStyles from "@material-ui/core/styles/makeStyles";


const CategoryCard = ({open, toggleCategoryOpen, categoryId, category, selectService, categoryServiceIds}) => {
    const classes = useStyles();
    const data = useContext(FirestoreContext);

    // Handle sizing
    const [topRef, topDimensions] = usePixelDimensions(); // Holds the dimensions of the top half of the card (always visible)
    const [botRef, botDimensions] = usePixelDimensions(); // Holds the dimensions of the bottom half (visible when open)
    const [closing, setClosing] = useState(false);

    useEffect(() => {
        if (!open && closing){
            const timer = setTimeout(() => {
                setClosing(false);
            }, 300);

            return () => {clearTimeout(timer);}
        }
    }, [closing, open]);

    const wrapperStyle = useMemo(() => {
        return {
            height: (open || closing) ? `${topDimensions.height + botDimensions.height + 8}px` : `${topDimensions.height}px`
        }
    }, [topDimensions, botDimensions, open, closing]);

    const toggle = useCallback(() => {
        if (open){setClosing(true);}
        toggleCategoryOpen(categoryId);
    }, [categoryId, toggleCategoryOpen, open]);

    // Create the contents of the top part

    const userMedia = data.hasOwnProperty('userMedia') ? data.userMedia : null;
    const topArea = useMemo(() => {
        let categoryImage = null;
        if (typeof category.mediaId === "string"){
            categoryImage = (
                <div className={classes.mediaCard}>
                    <div className={classes.mediaWrapper}>
                        { userMedia && userMedia.hasOwnProperty(category.mediaId) ? <ResponsivePicture
                            mediaId={category.mediaId}
                            formats={'320x107-Q80|375x125-Q80|414x138-Q80|540x180-Q80'}
                            xs={'calc(100vw - 1rem)'} // Full page - margins
                            sm={'calc(50vw - 0.5rem)'}  // Full page - margines, TODO: account for spacing
                            lg={'calc(21.66rem - 0.33rem)'}  // Full page - margins (note max width for container is 64rem), TODO: account for spacing
                        /> : null }
                    </div>
                </div>
            )
        }

        return(
            <CardActionArea onClick={toggle}>
                { categoryImage }
                {typeof category.name === 'string' && category.name.length > 0 ?
                    <CardHeader className={classes.cardHeader} title={category.name}/> : null}
                {typeof category.desc === 'string' && category.desc.length > 0 ?
                    <CardContent><Typography variant={'body2'}>{ category.desc }</Typography></CardContent> : null}
            </CardActionArea>
        )
    }, [classes, category, toggle, userMedia]);

    // Create the contents of the bottom part

    const handleSelectService = useCallback(event => {
        const serviceId = event.currentTarget.getAttribute('data-service-id');
        selectService(serviceId);
    }, [selectService]);

    const { services } = data;
    const botArea = useMemo(() => {
        return(
            <React.Fragment>
                <List className={classes.list}>
                    {
                        categoryServiceIds.map(serviceId => {
                            const service = services[serviceId];
                            const serviceName = service.name || '';
                            const servicePrice = service.price || '';
                            const primary = (
                                <Grid container justifyContent={'space-between'} wrap={'nowrap'}>
                                    <Grid item>
                                        { serviceName }
                                    </Grid>
                                    <Grid item>
                                        { servicePrice }
                                    </Grid>
                                </Grid>
                            );

                            return(
                                <ListItem button
                                          key={serviceId}
                                          data-service-id={serviceId}
                                          onClick={handleSelectService}
                                          className={classes.listItem}
                                >
                                    <ListItemText primary={primary} secondary={service.description || ''}/>
                                </ListItem>
                            )
                        })
                    }
                </List>
                <GalleryRowForCategory
                    categoryId={categoryId}
                    imageCountByBreakpoint={{
                        xs: 4
                    }}
                    galleryWidthByBreakpoint={{
                        xs: 'calc(100vw - 1rem)', // Full page - margins
                        sm: 'calc(50vw - 0.5rem)',  // Full page - margines, TODO: account for spacing
                        lg: 'calc(21.66rem - 0.33rem)',  // Full page - margins (note max width for container is 64rem), TODO: account for spacing
                    }}
                />
            </React.Fragment>
        )
    }, [classes, handleSelectService, categoryId, services, categoryServiceIds]);

    return(
        <div style={wrapperStyle} className={classes.wrapper}>
            <div className={classes.topHalfWrapper} ref={topRef}>
                <Card className={classes.topHalf} elevation={2}>
                    { topArea }
                </Card>
            </div>
            <div className={classes.bottomHalfWrapper}>
                <div ref={botRef}>
                    <Card className={`${classes.bottomHalf} ${open ? classes.bottomOpen : classes.bottomClosed}`}>
                        { botArea }
                    </Card>
                </div>
            </div>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap"
    },
    topHalfWrapper: {
        position: "relative",
        zIndex: 2,
    },
    bottomHalfWrapper: {
        // Display below top, and fill the remaining
        // space in the wrapper.
        position: "relative",
        flexGrow: "1",
        overflow: "hidden",
        zIndex: 1,

        top: "-4px",  // Tuck bottomHalfWrapper 4px underneath topHalfWrapper (avoid gap in corners)

        // Add negative margins and re-add them as padding
        // to allow the bottom card to cast a shadow to the
        // left, right and bottom (otherwise overflow: hidden
        // will hide the dropped shadow)
        marginLeft: "-4px",
        marginRight: "-4px",
        paddingLeft: "4px",
        paddingRight: "4px",
        paddingBottom: "4px",
    },
    topHalf: {
        position: "relative",
        overflow: "hidden",
        transition: "boxShadow 300ms ease-in-out",
        backgroundColor: theme.services.cardCategories.backgroundColor,
    },
    bottomHalf: {
        position: 'relative',
        transition: "transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1)",
        borderRadius: "0 0 4px 4px",

        paddingTop: "8px",  // bottomHalfWrapper is tucked 4px underneath topHalfWrapper + some space for shadow

        backgroundColor: theme.services.cardCategories.backgroundColor,
    },
    bottomClosed: {
        transform: "translateY(calc(-100% + 4px))"
    },
    bottomOpen: {
        transform: "translateY(0)"
    },
    mediaCard: {
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        paddingBottom: '33.33%',
        backgroundColor: 'rgba(0,0,0,0.2)'
    },
    mediaWrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    cardHeader: {
        '&:not(:last-child)': {
            paddingBottom: '0'
        }
    },
    card: {
        backgroundColor: theme.services.cardCategories.backgroundColor,
        transition: 'max-height 1s ease'
    },
    list: {
        padding: '0'
    },
    listItem: {
        '&:not(:first-child)': {
            borderTop: '1px solid rgba(0,0,0,0.2)'
        }
    }
}));

export default CategoryCard;