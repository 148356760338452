const fieldLengths = {
    fullName: {
        min: 5,
        max: 150
    },
    firstName: {
        min: 2,
        max: 150
    },
    lastName: {
        min: 2,
        max: 150
    },
    phone: {
        min: 4,
        max: 15,
        byPrefix: {
            '47': {
                max: 8,
                min: 8
            }
        }
    },
    address: {
        min: 5,
        max: 150
    },
    zip: {
        min: 2,
        max: 15
    },
    city: {
        min: 3,
        max: 150
    },
    notes: {
        max: 1500
    }
};

export default fieldLengths;