import React, {useState, useContext, useEffect, useLayoutEffect, useMemo} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import ServicesView from "../p2Service/ServicesView";
import TimeView from "../p3Time/TimeViewV2";
import DependencyLoading from '../p1Loading/DependencyLoading';
import {LazyLoaderContext} from "../../contexts/LazyLoaderContextV2";
import ProgressIndicator from "../ProgressIndicator/ProgressIndicator";
import CustomerInfo from "../p4Customer/CustomerInfo";
import alpha2CodeByCallingCode from '../../logic/formFormatting/phone/alpha2CodeByCallingCode';
import Confirmation from "../p6Confirmation/Confirmation";
import {FirestoreContext} from "../../contexts/FirestoreContextV2";
import {DebugModeContext} from "../../contexts/DebugModeContext";
import LegalFooter from "./LegalFooter";
import {TrackingContext} from "../../contexts/TrackingContextV3";
import ThankYou from "../p7ThankYou/ThankYou";

const pages = [
    {
        pageName: 'services',
        dependencies: [],
        dataDependencies: ['design' ,'services', 'categories']
    },
    {
        pageName: 'time',
        dependencies: ['moment-timezone'],
        dataDependencies: ['design' ,'services', 'providers', 'venues', 'policies', 'defaultOpeningHours']
    },
    {
        pageName: 'customerInfo',
        dependencies: [],
        dataDependencies: ['design']
    },
    {
        pageName: 'confirmation',
        dependencies: [],
        dataDependencies: ['design']
    },
    {
        pageName: 'thankYou',
        dependencies: [],
        dataDependencies: ['design']
    }
];

const pageNames = pages.map(p => p.pageName);

export default function (props){
    const classes = useStyles();
    const data = useContext(FirestoreContext);
    const lazy = useContext(LazyLoaderContext);
    const debug = useContext(DebugModeContext);
    const track = useContext(TrackingContext);

    // Notify parent page that the iFrame has
    // loaded the DOM, and it's ready to be
    // resized. The paren page will then stop
    // displaying the loading indicator over
    // the iFrame.

    useLayoutEffect(() => {
        window.parent.postMessage({
            alfalistDOMLoaded: true,
        }, "*");
    }, []);

    const [onPage, setOnPage] = useState(0);

    const [selection, setSelection] = useState({
        openCategories: [],
        serviceId: null,  // p2Service
        aptStartMoment: null,
        providerFilter: null,
        venueId: null,
        bookingTermsCheckboxChecked: false,
        bookingTermsImplicitlyAccepted: false,
        subscribeToNewsletter: false,
        subscribeToPromotions: false
    });

    // If we have rememberMe values saved on this devide we load
    // them here. Otherwise we just use the defaults (empty strings).
    const [info, setInfo] = useState(() => {
        try {
            const rememberMeInfoJson = localStorage.getItem("rememberMeCustomerInfo");
            if (rememberMeInfoJson){
                const rememberMeInfo = JSON.parse(rememberMeInfoJson);
                const createInfoObject = field => {
                    let val = '';
                    if (
                        rememberMeInfo.hasOwnProperty(field) &&
                        rememberMeInfo[field].hasOwnProperty('value') &&
                        typeof rememberMeInfo[field].value === 'string'
                    ){
                        val = rememberMeInfo[field].value;
                    }

                    return({value: val, valid: 'pending', error: ''});
                }

                return({
                    fullName: createInfoObject('fullName'),
                    firstName: createInfoObject('firstName'),
                    lastName: createInfoObject('lastName'),
                    email: createInfoObject('email'),
                    address: createInfoObject('address'),
                    zip: createInfoObject('zip'),
                    city: createInfoObject('city'),
                    country: createInfoObject('country'),
                    phone: createInfoObject('phone'),
                    phoneCC: createInfoObject('phoneCC'),
                    notes: {value: '', valid: 'pending', error: ''}  // No remember me for notes
                });
            }
        } catch (e){
            console.warn(e);
        }

        return({
            fullName: {value: '', valid: 'pending', error: ''},
            firstName: {value: '', valid: 'pending', error: ''},
            lastName: {value: '', valid: 'pending', error: ''},
            email: {value: '', valid: 'pending', error: ''},
            address: {value: '', valid: 'pending', error: ''},
            zip: {value: '', valid: 'pending', error: ''},
            city: {value: '', valid: 'pending', error: ''},
            country: {value: '', valid: 'pending', error: ''},
            phone: {value: '', valid: 'pending', error: ''},
            phoneCC: {value: '', valid: 'pending', error: ''},
            notes: {value: '', valid: 'pending', error: ''}
        });
    });

    // Automatically fill in phoneCC/country based on defaults when loaded
    useEffect(() => {
        let updates = {};

        if (
            data.design &&
            data.design.customerInfo &&
            data.design.customerInfo.defaultPhoneCC &&
            info.phone.value.length === 0  // Don't overwrite saved value (remember me)
        ){
            const defaultPhoneCC = data.design.customerInfo.defaultPhoneCC;
            let defaultPhoneCCValid = 'pending';
            if (alpha2CodeByCallingCode.hasOwnProperty(defaultPhoneCC)){
                defaultPhoneCCValid = 'yes';
            } else if (defaultPhoneCC.length > 0){
                defaultPhoneCCValid = 'no';
            }

            updates.phoneCC = {
                value: defaultPhoneCC,
                valid: defaultPhoneCCValid,
                error: ''
            };
        }

        if (
            data.design &&
            data.design.customerInfo &&
            data.design.customerInfo.defaultCountry &&
            info.country.value.length === 0  // Don't overwrite saved value (remember me)
        ){
            updates.country = {
                value: data.design.customerInfo.defaultCountry
            };
        }

        if (Object.keys(updates).length === 0){return;}

        setInfo(oldInfo => ({
            ...oldInfo,
            ...updates
        }));
    }, [data.design]);

    if (debug.debugMode){
        window.printSelection = () => {
            console.log(selection, info);
        };
    }


    /**
     * @param page "next", "previous", page name or page index
     */
    const getPageIndex = page => {
        let newPageIndex;
        if (page === 'next'){
            newPageIndex = onPage + 1;
        } else if (page === 'previous'){
            newPageIndex = onPage - 1;
        } else if (typeof page === 'number'){
            newPageIndex = page;
        } else if (typeof page === 'string'){
            newPageIndex = pageNames.indexOf(page);
        }

        return newPageIndex;
    };

    const scrollToTop = () => {
        document.getElementById('alfalistModuleWrapper').scrollIntoView(true);
    };

    const goToPage = page => {
        const pageIndex = getPageIndex(page);
        setOnPage(pageIndex);
        scrollToTop();
    };

    // Tracking screen views
    useEffect(() => {
        track('event', 'page_view', {
            page_title: `Booking_${onPage.toString()}_${pages[onPage].pageName}`,
            page_location: window.location.href,
        });
    }, [onPage, track]);

    const handleSelect = (field, value, goToPage = null) => {
        setSelection(oldSelection => ({
            ...oldSelection,
            [field]: value
        }));

        if (goToPage !== null){
            const pageIndex = getPageIndex(goToPage);
            setOnPage(pageIndex);
            scrollToTop();
        }
    };

    let page;
    if (
        pages[onPage].dataDependencies.some(d => data.status[d] === 'noData') ||  // Required data has not been loaded yet
        pages[onPage].dependencies.reduce((acc, cur) => acc || !lazy.moduleIsLoaded[cur], false)  // If required modules are not ready
    ){
        page =  <DependencyLoading/>;
    } else {
        switch (onPage) {
            case 0:
                page = <ServicesView goToPage={goToPage} setSelection={handleSelect}/>;
                break;
            case 1:
                page = <TimeView goToPage={goToPage} selectedServiceId={selection.serviceId} setSelection={handleSelect}/>;
                break;
            case 2:
                page = <CustomerInfo goToPage={goToPage} info={info} setInfo={setInfo}/>;
                break;
            case 3:
                page = <Confirmation goToPage={goToPage} selection={selection} info={info} setSelection={handleSelect}/>;
                break;
            case 4:
                page = <ThankYou selection={selection}/>;
                break;
            default:
                throw new Error('onPage out of range');
        }
    }

    return(
        <div className={classes.wrapper} id={'alfalistModuleWrapper'}>
            <div className={classes.container}>
                <ProgressIndicator onPage={onPage} goToPage={goToPage}/>
                { page }
                <LegalFooter/>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        maxWidth: '100vw',
        textAlign: 'center',
        boxSizing: 'border-box',
        paddingLeft: '0.65rem',
        paddingRight: '0.65rem',
        paddingBottom: '100px',  // iFrameResizer triggers at 100px intervals, this is a safety margin
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
        }
    },
    container: {
        display: 'inline-block',
        maxWidth: '100%',
        width: '65rem',
        textAlign: 'left',  // reset text align
    }
}));