import createAvailSlotsListener from "./listenForAvailSlotsV2";
import cloneDeep from 'lodash/cloneDeep';
import getPickableSlots from "../getPickableSlots";
import getEligibleProviderIds from "../../getEligibleProviderIds";

/**
 * This function handles the creation and responses of listeners needed for ServiceAlternatives.
 * @param {Object} data
 * @param {number} startOfDayTs
 * @param {number} endOfDayTs
 * @param {Array<Object>} alternatives
 * @param {number} displayAtOnce - How many alternatives should be displayed at once (max)
 * @param {string} venueId
 * @param {function} callback
 * @returns {function} Cleanup function. Detaches listeners.
 */
const createAlternativeListeners = (
    data,
    startOfDayTs,
    endOfDayTs,
    alternatives,
    displayAtOnce,
    venueId,
    callback
) => {
    const listenerData = [];
    const relevantAlternatives = alternatives.filter(a => a.enabled);

    // ************************************
    // ******** Handle results ************
    // ************************************

    const handleResults = () => {
        callback(cloneDeep(listenerData));

        // In case of no available slots, check if we should create more listeners
        createNextListener();
    };

    const createResultHandler = alternativeIndex => result => {
        const newData = {...listenerData[alternativeIndex]};
        const serviceId = relevantAlternatives[alternativeIndex].alternativeServiceId;

        newData.slots = result[venueId] || [];
        const eligibleProviders = getEligibleProviderIds(serviceId, venueId, data);
        const pickableSlots = getPickableSlots(result, serviceId, eligibleProviders, data);

        newData.availSlots = newData.slots.filter(slot =>
            Object.values(slot.availableByProviderId).some(t => t)
        );

        newData.pickableSlots = pickableSlots;

        // console.log("alternative listener response: ", newData);

        listenerData[alternativeIndex] = newData;
        handleResults();
    };

    const createNextListener = () => {
        const alternativeCount = relevantAlternatives.length;
        const alternativeIndex = listenerData.length;

        // All possible listeners are already created - no need to create more
        if (alternativeCount === listenerData.length){return;}

        // Max display space used up - no need to create more (include only non-loaded and alternatives with available slots)
        if (listenerData.filter(l => (l.availSlots === null || l.availSlots.length > 0))
            .length >= displayAtOnce){return;}

        // Create one-to-one index mapping between listenerData and alternatives
        const alternative = relevantAlternatives[alternativeIndex];

        const listener = createAvailSlotsListener(
            data,
            startOfDayTs,
            endOfDayTs,
            alternative.alternativeServiceId,
            createResultHandler(alternativeIndex)
        );

        listenerData.push({
            listener: listener,
            alternative: alternative,
            alternativeIndex: alternativeIndex,  // Can be used as key for display
            slots: null,
            availSlots: null,
        });

        createNextListener();  // Repeat until display space is used up, or no more alternatives
    };

    // Start listener creation
    createNextListener();

    // Return a cleanup function
    return () => {
        listenerData.forEach(l => {
            l.listener();
        });
    }
};

export default createAlternativeListeners;