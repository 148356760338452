import React from 'react';
import PropTypes from 'prop-types';

// Core components
import makeStyles from "@material-ui/core/styles/makeStyles";
// import IconButton from "@material-ui/core/IconButton";
import Grid from '@material-ui/core/Grid';

// Icons
// import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ResponsivePicture from "../../../../components/images/ResponsivePicture";
import {Typography} from "@material-ui/core";
// import Hidden from "@material-ui/core/Hidden";

const Lightbox = props => {
    const { media, index, setIndex, onClose } = props;
    const classes = useStyles();

    // const goNext = useCallback(event => {
    //     setIndex(index === media.length -1 ? 0 : index + 1);
    //     event.stopPropagation();
    //     return false;
    // }, [setIndex, media, index]);
    //
    // const goPrevious = useCallback(event => {
    //     setIndex(index === 0 ? media.length - 1 : index - 1);
    //     event.stopPropagation();
    //     return false;
    // }, [setIndex, media, index]);

    const goToIndex = index => event => {
        setIndex(index);
        event.stopPropagation();
        return false;
    };

    return(
        <div className={classes.backdrop} onClick={onClose}>
            <Grid container direction={'column'} className={classes.absoluteFill}>
                <Grid item className={classes.growingImageField}>
                    <Grid container alignItems={'center'} justify={'center'} className={classes.absoluteFill} wrap={'no-wrap'}>
                        {/*<Hidden xsDown>*/}
                        {/*    <Grid item>*/}
                        {/*        <IconButton onClick={goPrevious} className={classes.navigationButton}><ArrowLeftIcon/></IconButton>*/}
                        {/*    </Grid>*/}
                        {/*</Hidden>*/}
                        <Grid item className={classes.imageContainer}>
                            <ResponsivePicture
                                formats={'300xauto-Q80|500xauto-Q80'}
                                mediaId={media[index].mediaId}
                                className={classes.picture}
                                alt={media[index].desc || ''}
                                xs={'calc(100vw - 2rem)'}
                            />
                            <Typography component={'div'} className={classes.desc}>
                                { media[index].desc }
                            </Typography>
                        </Grid>
                        {/*<Hidden xsDown>*/}
                        {/*    <Grid item>*/}
                        {/*        <IconButton onClick={goNext} className={classes.navigationButton}><ArrowRightIcon/></IconButton>*/}
                        {/*    </Grid>*/}
                        {/*</Hidden>*/}
                    </Grid>
                </Grid>
                <Grid item>
                    <div className={classes.controlsContainer}>
                        <Grid container justify={'center'} className={classes.thumbnailContainer}>
                            {
                                media.map(({mediaId, desc}, i) => {
                                    return(
                                        <Grid item className={classes.thumbnail + (i === index ? ' ' + classes.thumbnailSelected : '')}>
                                            <div onClick={goToIndex(i)}>
                                                <ResponsivePicture
                                                    formats={'40x40-Q80|60x60-Q80'}
                                                    xs={'4rem'}
                                                    mediaId={mediaId}
                                                    alt={desc}
                                                />
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
};

Lightbox.propTypes = {
    mediaIds: PropTypes.arrayOf(PropTypes.exact({mediaId: PropTypes.string.isRequired, desc: PropTypes.string})).isRequired,
    index: PropTypes.number.isRequired,
    setIndex: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
};

const useStyles = makeStyles(theme => ({
    backdrop: {
        position: 'fixed',
        left: '0',
        right: '0',
        bottom: '0',
        top: '0',
        zIndex: '1',
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    absoluteFill: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    growingImageField: {
        flexGrow: '1',
        position: 'relative'
    },
    navigationButton: {
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.08)'
        },
        margin: '1rem'
    },
    imageContainer: {
        padding: '1rem 1rem 0.5rem 1rem',
        flexGrow: '1',
        textAlign: 'center',
    },
    picture: {
        width: 'auto',
        maxWidth: 'calc(100vw - 6rem)',
        maxHeight: 'calc(80vh - 6rem)'
    },
    controlsContainer: {
        paddingBottom: '1rem'
    },
    desc: {
        maxWidth: '80vw',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: 'white',
        pointerEvents: 'none',
        paddingTop: '0.5rem',
        display: 'block'
    },
    thumbnailContainer: {
        padding: '0 1rem 0 1rem'
    },
    thumbnail: {
        border: '1px solid white',
        width: '4rem',
        height: '4rem',
        backgroundColor: 'white',
        position: 'relative',
        margin: '0.12rem',
        cursor: 'pointer',
        overflow: 'hidden',
        '&>div': {
            overflow: 'hidden'
        },
        '&>div:hover': {
            opacity: '0.8',
        }
    },
    thumbnailSelected: {
        border: '5px solid white',
        '&>div': {
            margin: '-4px'
        }
    }
}));

export default Lightbox;