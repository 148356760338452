import React, {useContext, useMemo} from 'react';
import getDisplayDates from "../../../logic/calendar/getDisplayDates";
import {getMoment} from "../../../logic/timeHandler";

// core components
import makeStyles from '@material-ui/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";

// icons
import ArrowLeft from '@material-ui/icons/ChevronLeft'
import ArrowRight from '@material-ui/icons/ChevronRight';
import {FirestoreContext} from "../../../contexts/FirestoreContextV2";


export default function (props){
    const moment = getMoment();
    const data = useContext(FirestoreContext);
    const { policies } = data;

    const today = moment();
    const displayMoment = props.displayMoment;

    const dates = getDisplayDates(displayMoment, 'month');
    const classes = useStyles();

    // Max lead time
    const maxLeadTime = useMemo(() => {
        if (policies.hasOwnProperty('maxLeadTime')){
            const maxLeadTime = moment();
            maxLeadTime.add(policies.maxLeadTime.amount, policies.maxLeadTime.interval);
            maxLeadTime.endOf('day');
            return maxLeadTime;
        } else {
            return null;
        }
    }, [policies, moment]);

    // Should we display previous month button
    const startOfMonth = displayMoment.clone();
    startOfMonth.startOf('month');
    const displayPreviousButton = startOfMonth.isAfter(today, 'day');

    // Should we display next month button
    const endOfMonth = displayMoment.clone();
    endOfMonth.endOf('month');
    const displayNextButton = !maxLeadTime || endOfMonth.isBefore(maxLeadTime);

    const goToNextMonth = () => {
        props.navigateInCalendar('next', 'months');
    };

    const goToPrevMonth = () => {
        props.navigateInCalendar('previous', 'months');
    };

    const attachSelectHandler = moment => () => {
        if (moment.isBefore(today, 'day')){return;}

        const monthStart = displayMoment.clone();
        monthStart.startOf('month');
        const monthEnd = displayMoment.clone();
        monthEnd.endOf('month');

        if (moment.isBefore(monthStart, 'day')){
            goToPrevMonth();
        }

        if (moment.isAfter(monthEnd, 'day')){
            goToNextMonth();
        }

        props.selectDate(moment);
    };

    return(
        <React.Fragment>
            <Typography variant={'h4'}>Velg dato</Typography>
            <div className={classes.monthNav}>
                <div className={classes.monthNavButton} data-hide={displayPreviousButton ? 'n' : 'y'} onClick={goToPrevMonth}><IconButton><ArrowLeft/></IconButton></div>
                <div className={classes.monthNavText}>{ displayMoment.format('MMMM YYYY') }</div>
                <div className={classes.monthNavButton} data-hide={displayNextButton ? 'n' : 'y'} onClick={goToNextMonth}><IconButton><ArrowRight/></IconButton></div>
            </div>
            <div className={classes.container}>
                {
                    dates.map((dt, index) => {
                        let red = false;
                        if (
                            props.redDayCalculated &&
                            dt.daysInFuture >= 0 &&  // Only check days in the future (past days are greyed out)
                            dt.daysInFuture < props.redDayCalculated.length  // redDays are only calculated within the max lead time
                        ){
                            red = props.redDayCalculated[dt.daysInFuture];
                        }

                        return(
                            <div key={'daySlot_' + index}
                                 className={classes.date}
                                 data-today={dt.moment.isSame(today, 'day') ? 'y' : 'n'}
                                 data-outside-interval={dt.outsideInterval ? 'y' : 'n'}
                                 data-selected={dt.moment.isSame(props.selectedDate, 'day') ? 'y' : 'n'}
                                 data-in-the-past={dt.moment.isBefore(today, 'day') ? 'y' : 'n'}
                                 data-outside-leadtime={maxLeadTime && dt.moment.isSameOrAfter(maxLeadTime, 'day') ? 'y' : 'n'}
                                 data-red-day={red ? 'y' : 'n'}
                                 onClick={attachSelectHandler(dt.moment)}
                            >
                                <div className={classes.fillParent}>
                                    <div className={classes.dateText}>
                                        { dt.moment.date() }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        fontSize: 0,
        lineHeight: 0
    },
    monthNav: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        textTransform: 'capitalize'
    },
    monthNavButton: {
        '&[data-hide="y"]': {
            visibility: 'hidden'
        }
    },
    monthNavText: {
        flexGrow: 1,
        textAlign: 'center',
        fontFamily: theme.typography.fontFamily,
        fontSize: '1.5rem'
    },
    date: {
        display: 'inline-block',
        position: 'relative',
        boxSizing: 'border-box',
        width: '12.28%',
        paddingBottom: '12.28%',
        margin: '1%',
        border: '1px solid transparent',
        transition: 'all 0.2s',
        borderRadius: '50%',
        userSelect: 'none',
        '-webkit-tap-highlight-color': 'transparent',  // Prevent blue highlight on chrome mobile
        '&[data-today="y"]': {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
        '&[data-red-day="y"]': {
            color: 'red',
        },
        '&[data-outside-interval="y"]': {
            opacity: '0.5'
        },
        '&[data-selected="y"]': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main)
        },
        cursor: 'pointer',
        '&[data-selected="n"]:hover': {
            backgroundColor: theme.palette.primary.light
        },
        '&[data-in-the-past="y"]': {
            opacity: '0.5',
            cursor: 'auto',
            pointerEvents: 'none'
        },
        '&[data-outside-leadtime="y"]': {
            opacity: '0.5',
            cursor: 'auto',
            pointerEvents: 'none'
        }
    },
    fillParent: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dateText: {
        width: '100%',
        display: 'inline-block',
        // position: 'absolute',
        textAlign: 'center',
        fontFamily: `Europa,${theme.typography.fontFamily}`,
        fontSize: '1.2rem',
        verticalAlign: 'middle',
        // marginTop: '-100%',
        // left: '50%',
        // top: '50%',

        // lineHeight: '1.2rem',
        // paddingTop: 'calc(50% - 0.6rem)',
        // [theme.breakpoints.up('sm')]: {
        //     fontSize: '1.8rem',
        //     lineHeight: '1.8rem',
        //     paddingTop: 'calc(50% - 0.9rem)'
        // },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.3rem',
            // lineHeight: '1.3rem',
            // paddingTop: 'calc(50% - 0.65rem)'
        }
    }
}));