import React, {useState, useEffect, useCallback, useContext} from 'react';
import {getFirestore} from "../logic/firebaseConfig";
import {DebugModeContext} from "./DebugModeContext";

const FirestoreContext = React.createContext({});

const dataKeys = [
    'policies',
    'design',
    'categories',
    'services',
    'providers',
    'venues',
    'defaultOpeningHours',
    'userMedia',
    'serviceAlternatives'
];

const FirestoreContextProvider = ({ businessId, designId, visitString, children }) => {
    const debug = useContext(DebugModeContext);
    const [firebaseLoaded, setFireBaseLoaded] = useState(false);
    const onFirestoreLoaded = useCallback(() => {
        setFireBaseLoaded(true);
    }, []);

    // Set initial state. If server defaults are loaded, use those
    const [data, setData] = useState(() => {
        if (!businessId){throw new Error('Alfalist error: invalid businessId');}
        if (!designId){throw new Error('Alfalist error: invalid designId');}

        // Visit data
        let visit = null;
        if (visitString){
            try {
                const visitData = visitString;
                visit = {
                    ts: visitData.ts,
                    landing_url: visitData.landing_url
                };
            } catch (e){
                console.warn('Failed to parse visit string', visitString);
                console.warn(e);
                visit = null;
            }
        }

        console.log("businessId", businessId);

        const template = {
            businessId: businessId,
            designId: designId,
            onFirestoreLoaded: onFirestoreLoaded,  // Callback used by LazyLoaderContext
            status: {
                serverDefaultsLoaded: false
            }
        };
        dataKeys.forEach(key => {
            template.status[key] = 'noData';
            template[key] = {};
        });

        // If the serverDefaults are already loaded (common), set them immediately
        // console.log('%clooking for preloaded data', window.alfalistServerDefaults ? 'color:green' : 'color:red', window.alfalistServerDefaults);
        if (typeof window !== "undefined" && window.alfalistServerDefaults){
            const serverDefaultsKeys = Object.keys(window.alfalistServerDefaults);
            if (serverDefaultsKeys.length !== dataKeys.length){
                console.warn("serverDefaults, unexpected mismatch", serverDefaultsKeys, dataKeys);
            }

            serverDefaultsKeys.forEach(key => {
                template.status[key] = 'serverDefaults';
                if (!dataKeys.includes(key)){console.warn("Unexpected serverDefaults key: ", key);}
            });

            template.status.serverDefaultsLoaded = true;

            return {
                ...template,
                ...window.alfalistServerDefaults,
                visit: visit
            };
        }

        // Backup solution if the serverDefaults are not loaded (eg. cloud function cold start)
        return {...template, visit: visit};
    });

    const handleSnap = collection => snap => {
        let newData = {};
        if (  // If we're fetching single docs, not collections
            collection === 'design' ||
            collection === 'policies' ||
            collection === 'serviceAlternatives'
        ){
            newData = snap.data();
        } else {
            snap.forEach(doc => {
                newData[doc.id] = doc.data();
            });
        }

        setData(oldData => ({
            ...oldData,
            [collection]: newData,
            status: {
                ...oldData.status,
                [collection]: 'firebase'
            }
        }));
    };

    // Create listeners when firestore is loaded
    useEffect(() => {
        if (!firebaseLoaded){return;}
        const businessId = data.businessId;
        const designId = data.designId;
        const firestore = getFirestore();

        const servicesListener = firestore.collection('services')
            .where('business', '==', businessId)
            .where('hidden', '==', false)
            .onSnapshot(handleSnap('services'));

        const categoriesListener = firestore.collection('categories')
            .where('business', '==', businessId)
            .where('hidden', '==', false)
            .onSnapshot(handleSnap('categories'));

        const providersListener = firestore.collection('providers')
            .where('business', '==', businessId)
            .where('hidden', '==', false)
            .onSnapshot(handleSnap('providers'));

        const venuesListener = firestore.collection('venues')
            .where('business', '==', businessId)
            .where('hidden', '==', false)
            .onSnapshot(handleSnap('venues'));

        const defaultOpeningHoursListener = firestore.collection('defaultOpeningHours')
            .where('business', '==', businessId)
            .onSnapshot(handleSnap('defaultOpeningHours'));

        const mediaListener = firestore.collection('userMedia')
            .where('businessId', '==', businessId)
            .where('deleted', '==', false)
            .onSnapshot(handleSnap('userMedia'));

        const designListener = firestore.doc('designs/' + designId)
            .onSnapshot(handleSnap('design'));

        const policiesListener = firestore.doc('policies/' + businessId)
            .onSnapshot(handleSnap('policies'));

        const serviceAlternativesListener = firestore.doc('serviceAlternatives/' + businessId)
            .onSnapshot(handleSnap('serviceAlternatives'));

        const listeners = [
            servicesListener,
            categoriesListener,
            providersListener,
            venuesListener,
            defaultOpeningHoursListener,
            mediaListener,
            designListener,
            policiesListener,
            serviceAlternativesListener
        ];

        return () => {
            console.warn("Detaching firestore listeners (Should only happen on navigate away from booking page)");
            listeners.forEach(listener => listener());
        };
    }, [firebaseLoaded, data.businessId, data.designId]);

    // DELAYED SERVER DEFAULTS (eg. in case of a function cold start)
    useEffect(() => {
        if (typeof window === 'undefined'){return;}
        if (data.status.serverDefaultsLoaded){return;}

        const callback = serverDefaults => {
            console.log("%cServerDefaultsLoaded callback fired!", 'color: green', serverDefaults);
            setData(currentData => {
                const missingDataKeys = dataKeys.filter(key => currentData.status[key] === 'noData');
                if (missingDataKeys.length === 0){return currentData;}

                const newData = {...currentData, status: {...currentData.status}};
                missingDataKeys.forEach(key => {
                    if (!serverDefaults.hasOwnProperty(key)){
                        console.warn('serverDefaults missing key: ', key, serverDefaults);
                        return;
                    }

                    newData[key] = serverDefaults[key];
                    newData.status[key] = 'serverDefaults';
                });

                newData.status.serverDefaultsLoaded = true;
                // console.log("old data", currentData);
                // console.log("new data", newData);
                return newData;
            });
        };

        if (window.alfalistServerDefaults){
            // console.log('%cServer data now loaded, skipping listener creation.', 'color: green');
            callback(window.alfalistServerDefaults);
        } else {
            // console.log('%cAttaching server defaults listener', 'color: red');
            window.alfalistServerDefaultsLoadedCallback = callback;
        }
    }, [data]);

    // Debug
    if (debug.debugMode){
        window.debugPrintFirestore = () => {
            console.log(data);
        }
    }

    return(
        <FirestoreContext.Provider value={data}>
            { children }
        </FirestoreContext.Provider>
    );
};

export {FirestoreContext, FirestoreContextProvider};