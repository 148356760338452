let moment = null;
export const initMoment = async (locale, timezone) => {
    const prm1 = import('moment-timezone');
    let useLocale = locale;

    let prm2;
    switch(locale){
        case 'af':
            prm2 = import('moment/locale/af');
            break;
        case 'nb':
            prm2 = import('moment/locale/nb');
            break;
        case 'en-gb':
            prm2 = import('moment/locale/en-gb');
            break;
        default:
            prm2 = import('moment/locale/en-gb');
            useLocale = 'en-gb';
            console.error('Could not find moment locale. Defaulting to en-gb', locale);
    }

    const res = await Promise.all([prm1, prm2]);
    moment = res[0].default;

    // Adjust settings here
    moment.tz.setDefault(timezone);  // Set to business timezone -> warning should be displayed if user tz differs
    moment.locale(useLocale);  // Selected by the business
};

export const getMoment = () => {
    if (!moment){throw new Error('moment-timezone is not loaded yet!')}
    return moment;
};