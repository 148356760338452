import React, {useContext} from 'react';
import {FirestoreContext} from "../../../../contexts/FirestoreContextV2";

// Core components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import SimpleTimePickerVenue from "./SimpleTimePickerVenue";
import getEligibleVenueIds from "../../../../logic/getEligibleVenueIds";
import {useCurrentBreakpoint} from "../../../../customHooks/useCurrentBreakpoint";

const SimpleTimePicker = props => {
    const data = useContext(FirestoreContext);

    const relevantVenueIds = getEligibleVenueIds(props.selectedServiceId, data);
    const currentBreakpoint = useCurrentBreakpoint();

    // In single column view, hide entire time picker until a date is selected.
    if (!props.selectedDate && currentBreakpoint === 'xs'){
        return null;
    }
    
    return(
        <React.Fragment>
            <Typography variant={'h4'}>Ledige tidspunkter</Typography>
            <Grid container spacing={4}>
                {
                    relevantVenueIds.map(venueId => {
                        return(
                            <Grid item xs={12} md={6} key={venueId}>
                                <SimpleTimePickerVenue
                                    venueId={venueId}
                                    selectedServiceId={props.selectedServiceId}
                                    selectTime={props.selectTime}
                                    setSelection={props.setSelection}
                                    availSlots={props.availSlots}
                                    selectedDate={props.selectedDate}
                                    startOfDayTs={props.startOfDayTs}
                                    endOfDayTs={props.endOfDayTs}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </React.Fragment>
    )
};

export default SimpleTimePicker;