/**
 * Takes a providers, services, categories or similar object and returns an array of their ids sorted after the order
 * property.
 * @param dataObject
 * @return {string[]}
 */
export default function getIdsSortedByOrder(dataObject){
    const ids = Object.keys(dataObject);
    ids.sort((a, b) => (dataObject[a].order || 0) - (dataObject[b].order || 0));
    return ids;
};