import postalCodeFormatsByCC from "./postalCodeFormats";
import findMatchingFormat from "../findMatchingFormat";
import replaceXes from "../replaceXes";

/**
 *
 * @param {string} cc
 * @param {string} postalCode
 * @return {string} formatted postal code
 */
const formatPostalCode = (cc, postalCode) => {
    if (!postalCode || !cc || !postalCodeFormatsByCC.hasOwnProperty(cc)){
        return postalCode;
    }

    const format = findMatchingFormat(postalCode, postalCodeFormatsByCC[cc]);
    if (format){
        return replaceXes(postalCode, format);
    } else {
        return postalCode;
    }
};

export default formatPostalCode;