import React, {useContext, useState, useEffect} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {LocaleStringsContext} from "../../../contexts/LocaleStringsContext";
import {Typography} from "@material-ui/core";
import {LazyLoaderContext} from "../../../contexts/LazyLoaderContextV2";

const ServiceCircleComponent = props => {
    const service = props.service;
    const locale = useContext(LocaleStringsContext);
    const lazy = useContext(LazyLoaderContext);

    const classes = useStyles({
        serviceColor: service.color || null,
        serviceMedia: service.media || null
    });

    const [wrapperStyle, setWrapperStyle] = useState({});
    useEffect(() => {
        const imgSrc = props.service.imgSrc;

        if (
            !imgSrc ||
            !lazy.mediaIsLoaded[imgSrc]
        ){return;}

        setWrapperStyle({
            backgroundImage: `url(${imgSrc})`
        });

    }, [lazy.mediaIsLoaded, props.service.imgSrc]);

    return(
        <div className={classes.wrapper} onClick={props.onClick} style={wrapperStyle}>
            <div className={classes.circleContent}>
                <Typography className={classes.serviceName}>{ service.name || locale.services.unnamedService }</Typography>
                <Typography className={classes.servicePrice}>{ service.price }</Typography>
            </div>
            <div className={classes.hoverOverlay}/>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    wrapper: props => {
        const serviceColor = props.serviceColor || theme.palette.primary.main;

        return {
            width: '100%',
            paddingBottom: '100%',
            borderRadius: '50%',
            position: 'relative',
            backgroundColor: serviceColor,
            color: theme.palette.getContrastText(serviceColor),
            cursor: 'pointer',
            overflow: 'hidden',
            backgroundSize: 'cover'
        };
    },
    hoverOverlay: {
        backgroundColor: 'transparent',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        borderRadius: '50%',
        zIndex: 1,
        transition: 'background-color 0.2s',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.2)'
        }
    },
    circleContent: props => {
        if (!props.serviceMedia){
            return {
                position: 'absolute',
                top: '50%',
                left: '12%',
                right: '12%',
                transform: 'translateY(-50%)'
            }
        } else {
            return {
                position: 'absolute',
                top: '50%',
                left: '13%',
                right: '13%'
            }
        }
    },
    serviceName: {
        fontSize: '1rem',
        textAlign: 'center'
    },
    servicePrice: {
        fontSize: '0.9rem',
        textAlign: 'center'
    }
}));

export default ServiceCircleComponent;