import {getMoment} from "../../timeHandler";
import calculateOpeningHoursForProviders from "../calculateOpeningHoursForProvider";

/**
 * This function takes an array of apts (should be all apts in the relevant timeframe) and an object of opening
 * hours adjustments (same format as firestore: {<adjustmentId>: {...adjustmentData}, ...}) and calculates the
 * available slots for each venue.
 * The format of the result is:
 * {
 *     [<venueId>]: [
 *         {
 *             slotStartMoment: <momentObj>,
 *             slotEndMoment: <slotEndMoment>,
 *             slotStartTs: <number>,
 *             slotEndTs: <number>,
 *             availableByProviderId: {
 *                 [providerId]: <bool>,
 *                 ...restOfRelevantProviderIds
 *         }
 *         ...restOfSlotsInRange
 *     ]
 * }
 * @param {Object} data
 * @param {string} serviceId
 * @param {number} searchStartTs
 * @param {number} searchEndTs
 * @param {Array<Object>} arrayOfApts
 * @param {Object} openingHoursAdjustments
 * @returns {Object} Slots by venueId.
 */
const calculateAvailSlots = (
    data,
    serviceId,
    searchStartTs,
    searchEndTs,
    arrayOfApts,
    openingHoursAdjustments
) => {
    const moment = getMoment();
    if (!moment){throw new Error('Attempting calculateAvailSlots before moment is loaded.')}

    const allProviders = data.providers;
    const allVenues = data.venues;
    const service = data.services[serviceId];

    const now = moment();
    const searchStartMoment = moment.unix(searchStartTs);
    const searchEndMoment = moment.unix(searchEndTs);

    // *************************************************************
    // ******* Find relevant providers and venues ******************
    // *************************************************************

    const relevantProviderIds = Object.keys(allProviders)
        .filter(providerId => service.providers[providerId] && service.providers[providerId].publicBooking)  // Provider offers this service
        .filter(providerId => !allProviders[providerId].hidden)  // Provider is not hidden (fail-save, should not appear in allProviders)
        .filter(providerId => {  // Provider venue is not hidden (venue could be hidden, while provider is not)
            const venueId = allProviders[providerId].venue;
            return allVenues.hasOwnProperty(venueId) && !allVenues[venueId].hidden;
        });

    const relevantVenueIds = Object.keys(data.venues).filter(venueId => {
        return relevantProviderIds.some(providerId => data.providers[providerId].venue === venueId)
    });

    // *************************************************************
    // ******** Get opening hours for relevant providers ***********
    // *************************************************************

    const openingHours = calculateOpeningHoursForProviders(
        data,
        relevantProviderIds, // providerIds,
        moment.unix(searchStartTs), // searchStartMoment,
        moment.unix(searchEndTs), // searchEndMoment,
        openingHoursAdjustments // allAdjustments
    );

    // *************************************************************
    // ************* Calculate results pr. venue *******************
    // *************************************************************

    const result = {};
    relevantVenueIds.forEach(venueId => {
        const providerIdsForThisVenue = relevantProviderIds
            .filter(providerId => allProviders[providerId].venue === venueId);

        // If this venue has a slot size override, use that. Otherwise use the business default
        // const slotSize = allVenues[venueId].slotSizeOverride || data.policies.slotSize;
        const slotSize = 15;
        if (!slotSize){throw new Error("Could not find slotSize for this business.");}

        // These objects are used as templates when creating slots
        const allAvailTemplate = {};
        const allBlockedTemplate = {};
        providerIdsForThisVenue.forEach(providerId => {
            allAvailTemplate[providerId] = true;
            allBlockedTemplate[providerId] = false;
        });

        // *****************************************************
        // ******* Get mininum and maximum lead time ***********
        // *****************************************************

        const earliestWithinLeadTime = now.clone();
        const minLeadTime = data.policies.minLeadTime;
        if (!minLeadTime || !minLeadTime.hasOwnProperty('amount') || !minLeadTime.hasOwnProperty('interval')){
            throw new Error("Business policies is missing minLeadTime, or it's malformed.");
        }
        earliestWithinLeadTime.add(minLeadTime.amount, minLeadTime.interval);
        earliestWithinLeadTime.add(2, 'minutes');  // Finishing the rest of the booking process faster than this is unrealistic

        const latestWithinLeadTime = now.clone();
        const maxLeadTime = data.policies.maxLeadTime;
        if (!maxLeadTime || !maxLeadTime.hasOwnProperty('amount') || !maxLeadTime.hasOwnProperty('interval')){
            throw new Error("Business policies is missing maxLeadTime, or it's malformed.");
        }
        latestWithinLeadTime.add(maxLeadTime.amount, maxLeadTime.interval);

        // ************************************************************
        // ******* Create slots (no yet filled with block info) *******
        // ************************************************************

        const slots = [];

        for (const slotDay = searchStartMoment.clone(); slotDay.isSameOrBefore(searchEndMoment, 'day'); slotDay.add(1, 'days')){
            const startOfNextDay = slotDay.clone();
            startOfNextDay.add(1, 'days');
            startOfNextDay.startOf('day');

            const slotStart = slotDay.clone();
            const slotEnd = slotStart.clone();
            slotEnd.add(slotSize, 'minutes');

            while (slotEnd.isSameOrBefore(slotDay, 'day') || slotEnd.isSame(startOfNextDay, 'minute')){
                const leadTimeOk = slotStart.isAfter(earliestWithinLeadTime) && slotStart.isBefore(latestWithinLeadTime);
                const mm = slotStart.format('mm');
                const isHalfSlot = (mm === "15" || mm === "45");

                slots.push({
                    slotStartMoment: slotStart.clone(),
                    slotEndMoment: slotEnd.clone(),
                    slotStartTs: slotStart.unix(),
                    slotEndTs: slotEnd.unix(),
                    availableByProviderId: leadTimeOk ? {...allAvailTemplate} : {...allBlockedTemplate},
                    isHalfSlot: isHalfSlot,
                    halfSlotActiveByProvider: isHalfSlot ? {...allBlockedTemplate} : null
                });

                slotEnd.add(slotSize, 'minutes');
                slotStart.add(slotSize, 'minutes');
            }
        }

        const fullSlots = slots.filter(slot => !slot.isHalfSlot);

        // Activate relevant half-slots
        providerIdsForThisVenue.forEach(providerId => {
            const blocksForThisProviders = [].concat(
                arrayOfApts.filter(apt => apt.tentativeProvider === providerId),
                openingHours[providerId]
            );

            // console.log("Investigating blocks for this provider", blocksForThisProviders);

            blocksForThisProviders.forEach(block => {
                // Check if this block ends within the first half of a full half-hour slot
                // then we want to activate the half-slot for this provider

                const firstHalf = fullSlots.find(firstHalfOfSlot => {
                    // console.log("Comparing", moment.unix(firstHalfOfSlot.tsEnd).format("HH:mm"), moment.unix(block.tsStart).format("HH:mm"), moment.unix(block.tsEnd).format("HH:mm"));
                    return(
                        block.tsEnd > firstHalfOfSlot.slotStartTs &&
                        block.tsEnd <= firstHalfOfSlot.slotEndTs
                    );
                });

                if (firstHalf){
                    const firstHalfIndex = slots.findIndex(slot => slot === firstHalf);
                    slots[firstHalfIndex + 1].halfSlotActiveByProvider[providerId] = true;
                }
            });
        });

        // *******************************************************************
        // ******* Loop though the relevant blocks and mutate slots **********
        // *******************************************************************

        const relevantBlocks = [].concat(
            arrayOfApts.filter(apt => providerIdsForThisVenue.includes(apt.tentativeProvider)),
            ...providerIdsForThisVenue.map(providerId => openingHours[providerId])
        );

        // console.log("Slots", slots);

        relevantBlocks.forEach(block => {
            const firstBlockSlotIndex = slots.findIndex(slot => {
                return slot.slotEndTs > block.tsStart;
            });

            if (firstBlockSlotIndex === -1){  // Block is after the relevant window
                // console.log('Block outside range: ', block, slots[0]);
                return;
            }

            let firstFreeSlotAfterBlockIndex = slots.findIndex(slot => {
                return slot.slotStartTs >= block.tsEnd;
            });

            // If the block is in the last slot, there is no next slot, and .findIndex will be undefined
            if (firstFreeSlotAfterBlockIndex === -1){
                firstFreeSlotAfterBlockIndex = slots.length;
            }

            for (let i = firstBlockSlotIndex; i < firstFreeSlotAfterBlockIndex; i++){
                slots[i].availableByProviderId[block.tentativeProvider] = false;
            }
        });

        result[venueId] = slots;
    });

    return result;
};

export default calculateAvailSlots;