import React, {useContext, useEffect, useMemo, useState} from 'react';
import {FirestoreContext} from "./FirestoreContextV2";
// import getIdsSortedByOrder from "../logic/getIdsSortedByOrder";
import {initFirebase, initFirebaseFunctions} from "../logic/firebaseConfig";
import {initMoment} from "../logic/timeHandler";
// import loadImage from "../logic/loadImage";

const LazyLoaderContext = React.createContext({});

const getLoadingQueue = (data, openCategories, onPage) => {
    const queue = [];
    const isQueued = {};  // Used to prevent the same image from being added multiple times

    // If we haven't received serverDefaults, set up firebase immediately in case of a cold start for the cloud function
    if (!data.status.serverDefaultsAreLoaded){
        queue.push({type: 'module', value: 'firebase'});
        isQueued.firebase = true;
    }

    // If we're on the time page, the modules are critical
    if (onPage > 0){
        // First moment-timezone -> needed for date select
        queue.push({type: 'module', value: 'moment-timezone'});
        isQueued['moment-timezone'] = true;

        if (
            data.design &&
            data.design.locale &&
            data.design.locale.moment
        ){
            queue.push({type: 'module', value: 'moment-timezone-locale'});
            isQueued['moment-timezone-locale'] = true;
        }

        // Then firebase -> needed for time select
        queue.push({type: 'module', value: 'firebase'});
        isQueued.firebase = true;

        // Finally firebase functions (used on the confirmation page)
        queue.push({type: 'module', value: 'firebase/functions'});
        isQueued['firebase/functions'] = true;
    }

    // Prioritize the category images of open categories
    // if (
    //     onPage === 0 &&
    //     openCategories.length > 0
    // ){
    //     openCategories
    //         .filter(categoryId => data.categories[categoryId].hasOwnProperty('imgSrc'))
    //         .forEach(categoryId => {
    //         const imgSrc = data.categories[categoryId].imgSrc;
    //
    //         if (isQueued[imgSrc]){return;}
    //
    //         queue.push({type: 'media', value: imgSrc});
    //         isQueued[imgSrc] = true;
    //     });
    // }

    // Then service images of open categories
    // if (
    //     onPage === 0 &&
    //     openCategories.length > 0
    // ){
    //     getIdsSortedByOrder(data.services).forEach(serviceId => {
    //         const service = data.services[serviceId];
    //
    //         if(
    //             !openCategories.includes(service.category) ||
    //             !service.hasOwnProperty('imgSrc') ||
    //             isQueued[service.imgSrc]
    //         ){return;}
    //
    //         queue.push({type: 'media', value: service.imgSrc});
    //         isQueued[service.imgSrc] = true;
    //     });
    // }

    // Then category images for categories that are not open
    // const addClosedCategoryMedia = () => {
    //     getIdsSortedByOrder(data.categories)
    //         .forEach(categoryId => {
    //             const category = data.categories[categoryId];
    //
    //             if (
    //                 !category.hasOwnProperty('imgSrc') ||
    //                 isQueued[category.imgSrc]
    //             ){return;}
    //
    //             queue.push({type: 'media', value: category.imgSrc});
    //             isQueued[category.imgSrc] = true;
    //         });
    // };
    //
    // if (onPage === 0){
    //     addClosedCategoryMedia();
    // }

    // Load modules
    if (!isQueued['moment-timezone']){
        isQueued['moment-timezone'] = true;
        queue.push({type: 'module', value: 'moment-timezone'});
    }

    if (
        data.design &&
        data.design.locale &&
        data.design.locale.moment &&
        !isQueued['moment-timezone-locale']
    ){
        queue.push({type: 'module', value: 'moment-timezone-locale'});
        isQueued['moment-timezone-locale'] = true;
    }

    if (!isQueued.firebase){
        isQueued.firebase = true;
        queue.push({type: 'module', value: 'firebase'});
    }

    if (!isQueued['firebase/functions']){
        isQueued['firebase/functions'] = true;
        queue.push({type: 'module', value: 'firebase/functions'})
    }

    // Then add any remaining category images, even if we've progressed past service select
    // addClosedCategoryMedia();

    // Load service images for open categories, even if we've progressed past service select
    // openCategories.forEach(categoryId => {
    //     getIdsSortedByOrder(data.services)
    //         .forEach(serviceId => {
    //             const service = data.services[serviceId];
    //
    //             if (
    //                 service.category !== categoryId ||
    //                 !service.imgSrc ||
    //                 isQueued[service.imgSrc]
    //             ){return;}
    //
    //             queue.push({type: 'media', value: service.imgSrc});
    //             isQueued[service.imgSrc] = true;
    //         });
    // });

    // Finally load service images for closed categories
    // getIdsSortedByOrder(data.services)
    //     .forEach(serviceId => {
    //         const service = data.services[serviceId];
    //
    //         if (
    //             !service.imgSrc ||
    //             isQueued[service.imgSrc]
    //         ){return;}
    //
    //         queue.push({type: 'media', value: service.imgSrc});
    //         isQueued[service.imgSrc] = true;
    //     });

    return queue;
};

const LazyLoaderContextProvider = props => {
    const data = useContext(FirestoreContext);

    const [onPage, setOnPage] = useState(0);  // Updated by navigating
    const [openCategories, setOpenCategories] = useState([]);  // Updating by opening categories

    const [status, setStatus] = useState(() => {
        return {
            // mediaIsLoaded: {},
            moduleIsLoaded: {
                'moment-timezone': false,
                'moment-timezone-locale': false,
                'firebase/app': false,
                'firebase/firestore': false,
                'firebase/functions': false
            },
            currentElement: null,
            registerChange: {
                setOpenCategories: setOpenCategories,
                setOnPage: setOnPage
            }
        }
    });

    const queue = useMemo(() => {
        return getLoadingQueue(data, openCategories, onPage)
    }, [data, onPage, openCategories]);

    const loadElement = (nextElement, status, queue, data) => {
        let prm, abort;
        let markAsComplete = [nextElement.value];
        let isAborted = false;

        // console.log("%cLazyLoader: Starting to load ", "color: orange", nextElement.type, nextElement.value);

        if (nextElement.type === 'module'){
            switch (nextElement.value){
                case "firebase":
                    prm = initFirebase();
                    markAsComplete = ['firebase', 'firebase/app', 'firebase/firestore'];
                    break;
                case "moment-timezone":
                    // Check if locale setting is loaded
                    // This is to compensate for an edge-case where no data was received from getServerDefaults, and
                    // firestore is loaded, but the listener for design has not yet received it's data.
                    // In that case load moment-timezone, but ignore the locale for now
                    if (
                        data.design &&
                        data.design.locale &&
                        data.design.locale.moment &&
                        data.design.locale.timezone
                    ){
                        prm = initMoment(data.design.locale.moment, data.design.locale.timezone);
                        markAsComplete = ['moment-timezone', 'moment-timezone-locale'];
                    } else {
                        prm = import('moment-timezone');  // In the edge case, load just moment-timezone, and handle settings later
                    }
                    break;
                case "moment-timezone-locale":
                    prm = initMoment(data.design.locale.moment, data.design.locale.timezone);
                    break;
                case "firebase/functions":
                    prm = initFirebaseFunctions();
                    break;
                default:
                    console.error(nextElement.value);
                    throw new Error('LazyLoadContext, unexpected case.');
            }
        } else if (nextElement.type === 'media'){
            // If the image can't be loaded, just use an empty image object to convey the problem
            // const loading = loadImage(nextElement.value);
            //
            // prm = loading.promise.catch(err => {
            //     return new Image();
            // });
            //
            // abort = () => {
            //     console.log('%cLazyLoad: Aborted loading of', "color:red", nextElement.value);
            //     loading.abort();
            //     isAborted = true;
            // };
            throw new Error('Loading images with LazyLoaderContext is deprecated.');
        } else {
            throw new Error('Type should be media or module.');
        }

        setStatus(oldStatus => ({
            ...oldStatus,
            currentElement: {...nextElement, abort: abort}
        }));

        prm.then(() => {
            if (isAborted){return;}

            setStatus(oldStatus => {
                const s = nextElement.type === 'module' ? 'moduleIsLoaded' : 'mediaIsLoaded';
                const newState = {...oldStatus};

                markAsComplete.forEach(value => newState[s][value] = true);

                return newState;
            });

            // console.log("%cLazyLoader: Successfully loaded", "color:green", nextElement.value);
            if (nextElement.value === 'firebase'){
                data.onFirestoreLoaded();
            }
        }).catch(err => {
            if (isAborted){return;}
            console.error("Error loading element: ", err);
        });
    };

    // This effect starts the loading
    useEffect(() => {
        const currentElement = status.currentElement;

        const nextElement = queue.find(element => {
            if (!currentElement){return true;}

            return(
                // !status.mediaIsLoaded[element.value] &&
                !status.moduleIsLoaded[element.value] &&
                element.value !== currentElement.value
            );
        });

        if (!nextElement){
            // console.log("Loading queue empty.");
            return;
        }

        let lastElementDone;
        if (currentElement === null){
            lastElementDone = true;
        } else {
            const key = currentElement.type === 'module' ? 'moduleIsLoaded' : 'mediaIsLoaded';
            lastElementDone = status[key][currentElement.value];
        }

        // If the last element is done -> go ahead and load the next one
        if (lastElementDone){
            loadElement(nextElement, status, queue, data);
            // return;
        }

        // When the user navigates from the services view to the time view while an image is loading, just abort the
        // download and start loading the required modules right away.
        // if (
        //     currentElement.type === 'media' &&
        //     nextElement.type === 'module'
        // ){
        //     currentElement.abort();
        //     loadElement(nextElement, status, queue, data);
        // }
    }, [status, queue, data]);

    return(
        <LazyLoaderContext.Provider value={status}>
            { props.children }
        </LazyLoaderContext.Provider>
    )
};

export { LazyLoaderContext, LazyLoaderContextProvider };