import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';

/**
 * This is a custom hook that can be used to retrieve the pixel dimensions of a DOM element.
 * IMPORTANT: the ref must be attached to a DOM-component, not a react component.
 * (see more: https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components).
 * */

const getDimensions = ref => {
    if (!ref || !ref.current){return null;}

    const rect = ref.current.getBoundingClientRect();
    const w = rect.width;
    const h = rect.height;

    return {width: w, height: h};
};


const usePixelDimensions = () => {
    const ref = useMemo(() => React.createRef(), []);

    // Update state
    const [dimensions, setDimensions] = useState({width: null, height: null});
    const recalc = useCallback(() => {
        const newDimensions = getDimensions(ref);

        if (!newDimensions){return;}
        if (
            newDimensions.width === dimensions.width &&
            newDimensions.height === dimensions.height
        ){
            // console.log('%cRecalc interrupted.', 'color: green');
            return;
        }

        setDimensions(oldDimensions => {
            if (
                newDimensions.width === oldDimensions.width &&
                newDimensions.height === oldDimensions.height
            ){
                // console.log('%cRecalc returned old dims.', 'color: yellow');
                return oldDimensions;
            } else {
                // console.log(`%cRecalc applied, w: ${newDimensions.width} h: ${newDimensions.height}`, 'color: red');
                return {width: newDimensions.width, height: newDimensions.height};
            }
        });
    }, [ref, dimensions]);

    // If the DOM changes, we should recalc (no dependencies! Should trigger on all updates)
    // Note: recalc should not be needed as dependency since it's run after every render, correct?
    useLayoutEffect(() => {
        recalc();
    });

    // If the window is resized, we should recalc
    // note: must be reattached on ref change, as ref is re
    useEffect(() => {
        let cancelCallback = false;

        // This function fires on every window.resize event, but only calls the recalc function 100ms after, and only
        // if the dimensions have not changed in that time period. This means that recalc will only be fired after
        // the window size has settled for at least 100ms.
        const throttledResize = () => {
            const startDims = getDimensions(ref);

            setTimeout(() => {
                if (cancelCallback){return;}

                const delayedDims = getDimensions(ref);
                if (
                    startDims.width !== delayedDims.width ||
                    startDims.height !== delayedDims.height
                ){return;}

                recalc();
            }, 100);
        };

        // Should fire on resize
        window.addEventListener('resize', throttledResize);

        // Cleanup
        return () => {
            cancelCallback = true;
            window.removeEventListener('resize', throttledResize)
        };
    }, [ref, recalc]);

    return [ref, dimensions];
};

export default usePixelDimensions;