import createAptsListener from "./createAptsListener";
import createAdjustmentsListener from "./createAdjustmentsListener";
import calculateAvailSlots from "./calculateAvailSlots";

/**
 * Creates a listener for available slots in the search window, for a particular service
 * @param data {Object} firestore data
 * @param searchStartTs {number} unix timestamp
 * @param searchEndTs {number} unix timestamp
 * @param serviceId {string} The selected service
 * @param callback {function} The callback function to deliver the results
 */
const createAvailSlotsListener = (
    data,
    searchStartTs,
    searchEndTs,
    serviceId,
    callback
) => {
    const businessId = data.businessId;
    const service = data.services[serviceId];

    if (service.hidden){throw new Error('This service is hidden');}

    // *****************************************************************
    // *************** Callback functions for new data *****************
    // *****************************************************************

    let aptsResult, adjustmentsResult;

    const handleNewResults = () => {
        if (!aptsResult || !adjustmentsResult){return;}  // Don't fire callback until all info is ready

        const result = calculateAvailSlots(
            data,
            serviceId,
            searchStartTs,
            searchEndTs,
            aptsResult,
            adjustmentsResult
        );

        callback(result);
    };

    const handleAptsResult = results => {
        aptsResult = results;
        handleNewResults();
    };

    const handleAdjustmentsResult = results => {
        adjustmentsResult = results;
        handleNewResults();
    };

    // Create listeners
    const aptsListener = createAptsListener(businessId, searchStartTs, searchEndTs, handleAptsResult);
    const adjustmentsListener = createAdjustmentsListener(businessId, searchStartTs, searchEndTs, handleAdjustmentsResult);

    // Return cleanup function
    return () => {
        aptsListener();
        adjustmentsListener();
    };
};

export default createAvailSlotsListener;