/**
 * Returns true if service is active for provider for this service. Does not take into account hidden providers,
 * services or venues.
 * @param {string} serviceId
 * @param {string} providerId
 * @param {Object} data
 * @return {boolean} publicBooking for provider
 */
export default function (serviceId, providerId, data) {
    const service = data.services[serviceId];
    return (
        service.providers.hasOwnProperty(providerId) &&
        service.providers[providerId].publicBooking
    );
}