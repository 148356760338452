import countryNamesEN from "./countryNamesEN";
import countryNamesNativeLocale from "./countryNamesNativeLocale";

let cache = null;
let cacheLocale = null;
const getCountryNamesByLocale = locale => {
    if (cache && locale === cacheLocale){return cache;}

    const localeOverrides = (countryNamesNativeLocale[locale] || {});
    cache = {...countryNamesEN, ...localeOverrides};
    cacheLocale = locale;

    return cache;
};

export default getCountryNamesByLocale;