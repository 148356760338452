import React, {useContext, useEffect, useMemo} from 'react';
import {FirestoreContext} from "../../contexts/FirestoreContextV2";
import {ErrorReportingContext} from "../../contexts/ErrorBoundary";
import getMediaVariantUrl from "../../logic/media/getMediaVariantUrl";
import PropTypes from "prop-types";
import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";

/**
 * This component handles loading of the correct image based on its own size, and displays a medium-style placeholder
 * image while the image is loading.
 */
const ResponsivePicture = props => {
    const data = useContext(FirestoreContext);
    const errorReporting = useContext(ErrorReportingContext);
    const theme = useTheme();
    const classes = useStyles();

    const { mediaId, formats, xs, sm, md, lg, xl } = props;
    const { userMedia } = data;

    const formatsArray = useMemo(() => {
        return formats.split('|');
    }, [formats]);

    const sizes = useMemo(() => {
        const mediaWidths = {xs: xs, sm: sm, md: md, lg: lg, xl: xl};

        const s = ['xl','lg','md','sm','xs'].filter(bp => !!mediaWidths[bp]).map(bp => {
            const mediaWidth = mediaWidths[bp];
            const breakpoint = theme.breakpoints.values[bp];

            return `(min-width: ${breakpoint}px) ${mediaWidth}`
        });

        s.push('100vw');  // Default backup
        return s.join(', ');
    }, [xs,sm,md,lg,xl, theme]);

    const media = useMemo(() => {
        if (
            !mediaId ||
            !userMedia ||
            !userMedia.hasOwnProperty(mediaId) ||
            !formats ||
            formats.length === 0
        ){return null;}

        return userMedia[mediaId];
    }, [userMedia, mediaId, formats]);

    const [webpSrcSet, jpegSrcSet, srcFallback] = useMemo(() => {
        if (
            !media ||
            !Array.isArray(media.variants) ||
            media.variants.length === 0
        ){
            return [null, null, null];
        }

        const relevantVariants = media.variants.filter(v => formatsArray.includes(v.split('@')[0]));

        if (relevantVariants.length === 0){
            errorReporting.nonBreakingError(new Error(`No relevant variants for media ${mediaId}`));
            return [null, null, null];
        }

        let fallbackUrl;
        const [webpSet, jpegSet] = ['webp', 'jpeg'].map(extension => {
            const variants = relevantVariants.map(prefix => {
                const variantResolution = prefix.split('-').shift();
                const softwareWidthString = variantResolution.split('x')[0];

                const softwareWidth = parseInt(softwareWidthString, 10);
                // const softwareHeight = parseInt(softwareHeightString, 10);
                // const aspectRatio = softwareWidth / softwareHeight;

                const pixelDensity = parseInt(prefix.charAt(prefix.length - 2), 10);

                return {
                    src: getMediaVariantUrl(media, prefix, extension),
                    hardwareWidth: pixelDensity * softwareWidth,
                    // aspectRatio: aspectRatio
                };
            }).sort((a, b) => a.hardwareWidth - b.hardwareWidth);  // Sort from smallest to largest (srcset should be ordered)

            // Set fallback
            if (extension === 'jpeg'){
                fallbackUrl = variants[0].src;
            }

            return variants.map(v => `${v.src} ${v.hardwareWidth}w`).join(',');
        });

        return [webpSet, jpegSet, fallbackUrl];
    }, [media, errorReporting, formatsArray, mediaId]);

    // ****************************************
    // ******* Medium-style placeholder? ******
    // ****************************************

    // Report errors
    useEffect(() => {
        if (!mediaId){
            errorReporting.nonBreakingError(new Error('ResponsivePicture is missing mediaId.'));
        }
    }, [mediaId, errorReporting]);

    if (!webpSrcSet){return null;}

    return(
        <picture className={classes.picture}>
            <source type={'image/webp'} srcSet={webpSrcSet} sizes={sizes}/>
            <source type={'image/jpeg'} srcSet={jpegSrcSet} sizes={sizes}/>
            <img src={srcFallback} className={classes.picture + (props.className ? ' ' + props.className : '')} alt={props.alt || ''}/>
        </picture>
    )
};

const useStyles = makeStyles(theme => ({
    picture: {
        width: '100%',
        height: 'auto',
        verticalAlign: 'middle' // The image will behave like a character, this removes small bottom margin
    }
}));

ResponsivePicture.propTypes = {
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    formats: PropTypes.string.isRequired,
    mediaId: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string
};

export default ResponsivePicture;