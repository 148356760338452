import React, {useContext, useEffect, useState} from 'react';
import localeTemplate from "../locales/localeTemplate";
import {FirestoreContext} from "./FirestoreContextV2";


const LocaleStringsContext = React.createContext({});

const LocaleStringsContextProvider = props => {
    const data = useContext(FirestoreContext);

    const [locale, setLocale] = useState({
        ...localeTemplate,
        localeCode: 'NOT_LOADED'
    });

    useEffect( () => {
        if (
            !data.design ||
            !data.design.locale ||
            !data.design.locale.strings
        ){return;}

        let prm;
        switch(data.design.locale.strings){
            case 'no':
                prm = import('../locales/NO');
                break;
            case 'en':
                prm = import('../locales/EN');
                break;
            default:
                console.warn(`Could not load locale ${data.design.locale}, using default EN locale.`);
                prm = import('../locales/EN');
        }

        prm.then(loadedLocale => {
            setLocale(loadedLocale.default);
        });

    }, [data.design]);

    return(
        <LocaleStringsContext.Provider value={locale}>
            { props.children }
        </LocaleStringsContext.Provider>
    )
};

export { LocaleStringsContext, LocaleStringsContextProvider };