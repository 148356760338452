import React, {useCallback, useContext} from 'react';
import {FirestoreContext} from "../../../contexts/FirestoreContextV2";

// Core components
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import {LocaleStringsContext} from "../../../contexts/LocaleStringsContext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ResponsivePicture from "../../../components/images/ResponsivePicture";

// Icons
import PersonIcon from '@material-ui/icons/Person';
import {TrackingContext} from "../../../contexts/TrackingContextV3";

const SimpleProviderPicker = props => {
    const data = useContext(FirestoreContext);
    const locale = useContext(LocaleStringsContext);
    const track = useContext(TrackingContext);
    const classes = useStyles();

    const { onChange } = props;
    const handleSetProviderFilter = useCallback(event => {
        const newVal = event.target.value;

        track("event", "select_provider", {
            provider_id: newVal
        });

        onChange(newVal);
    }, [track, onChange]);

    const renderValue = providerId => {
        if (providerId === 'any'){
            return locale.providerSelect.any;
        }

        return data.providers[providerId].name || locale.providerSelect.unnamedProvider;
    };

    // Load provider images
    // const [providerImages, setProviderImages] = useState({});

    const displayAvatars = props.options.some(p => data.providers[p].mediaId);  // At least one provider has image

    return(
        <div className={classes.wrapper}>
            <div>
                <Typography variant={'h5'} className={classes.venueName}>{ data.venues[props.venueId].name }</Typography>
            </div>
            <div>
                <Grid container justifyContent={'flex-end'}>
                    <Grid item xs={8}>
                        <FormControl fullWidth>
                            <Select value={props.providerFilter} onChange={handleSetProviderFilter} renderValue={renderValue}>
                                <MenuItem key={'anyProvider'} value={'any'}>{ locale.providerSelect.any }</MenuItem>
                                {
                                    props.options.map(providerId => {
                                        const provider = data.providers[providerId];

                                        let avatar;
                                        if (!displayAvatars){
                                            avatar = null;
                                        } else {
                                            let avatarContent;

                                            if (provider.mediaId){
                                                avatarContent = <ResponsivePicture
                                                    formats={'40x40-Q80|60x60-Q80'}
                                                    mediaId={provider.mediaId}
                                                    xs={'40px'}
                                                />;
                                            } else {
                                                avatarContent = <PersonIcon/>
                                            }

                                            avatar = (
                                                <ListItemAvatar>
                                                    <Avatar component={'div'} alt={provider.name}>
                                                        { avatarContent }
                                                    </Avatar>
                                                </ListItemAvatar>
                                            );
                                        }

                                        return(
                                            <MenuItem key={providerId}
                                                      value={providerId}
                                            >
                                                { avatar }
                                                { provider.name || locale.providerSelect.unnamedProvider }
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '1rem'
    },
    venueName: {
        textAlign: 'center',
        marginBottom: '1rem'
    },
}));

export default SimpleProviderPicker;