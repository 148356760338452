const values = {};

/**
 * This function can be used to prevent actions from being triggered multiple times.
 * @param {string} actionId - identifier for the action
 * @return {Array} - [<bool> action already taking place, function to be called when done]
 */
const debounce = actionId => {
    if (!actionId){throw new Error('debounce - missing actionId');}

    const deactivate = () => {values[actionId] = false;};

    if (values[actionId]){
        return [true, deactivate];
    }

    values[actionId] = true;
    return [false, deactivate];
};

export default debounce;