import React, {useCallback, useMemo, useState, useRef, useContext} from 'react';

const RedDaysContext = React.createContext({});

export const RedDaysContextProvider = ({ children }) => {
    const [redDaysData, setRedDaysData] = useState(null);

    const requestRedDayData = useCallback(() => {
        fetch('https://alfalist-instant-availability-node.benediktevippeextensions.no/')
            .then(response => response.json())
            .then(data => {
                //console.log('Fetched red day data', data);
                setRedDaysData(data);
            })
            .catch(error => {
                console.warn('Failed to fetch red day data');
                console.warn(error);
            });
    }, []);

    const intervalRef = useRef(null);
    const intervalListeners = useRef(0);
    const keepRedDaysDataUpdated = useCallback(() => {
        // Send an immediate request for red day data
        requestRedDayData();

        // Set up an interval to keep the data updated
        if (intervalListeners.current === 0){
            intervalRef.current = setInterval(() => {
                requestRedDayData();
            }, 1000 * 60);
        }

        intervalListeners.current += 1;

        // Once no more listeners are using this hook, clear the interval
        return () => {
            intervalListeners.current -= 1;
            if (intervalListeners.current === 0){
                clearInterval(intervalRef.current);
            }
        }
    }, []);

    const contextValue = useMemo(() => ({
        data: redDaysData,
        keepRedDaysDataUpdated: keepRedDaysDataUpdated,  // When redDaysData is actively used
        requestRedDayData: requestRedDayData  // When a single request is enough (typically pre-emptively)
    }), [redDaysData]);

    return(
        <RedDaysContext.Provider value={contextValue}>
            { children }
        </RedDaysContext.Provider>
    );
};

export const useRedDays = () => {
    return useContext(RedDaysContext);
};