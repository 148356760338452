import nationalFormats from "./nationalPhoneNumberFormats";
import allCountryCodes from './alpha2CodeByCallingCode';
import replaceXes from "../replaceXes";
import findMatchingFormat from "../findMatchingFormat";

/**
 * When the phone number is not in focus - format it based on the country code (eg. +47 -> "123 45 678")
 * @param {string} intlCode - Eg. "47"
 * @param {string} phoneNumber
 * @return {string} "123 45 678" for intlCode = "47"
 */
const formatPhoneNumber = (intlCode, phoneNumber) => {
    // Invalid country code
    if (intlCode.length === 0 || !allCountryCodes.hasOwnProperty(intlCode)){
        return phoneNumber;
    }

    // Check if we have a number format for this country
    const CC = allCountryCodes[intlCode];
    if (CC.length > 0 && nationalFormats.hasOwnProperty(CC)){

        // Try to find a format for this country that matches the number of digits in phoneNumber
        const format = findMatchingFormat(phoneNumber, nationalFormats[CC]);

        if (format){
            return replaceXes(phoneNumber, format);
        } else {
            return phoneNumber;
        }
    } else {
        // No recorded standard formatting for this country -> display the number as it was entered
        return phoneNumber;
    }
};

export default formatPhoneNumber;