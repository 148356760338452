const countryNamesNativeLocale = {
    no: {
        NO: 'Norge'
    },
    pl: {
        PL: 'Polska'
    },
    en: {}  // No overrides needed
};

export default countryNamesNativeLocale;