import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import SimpleDatePicker from './DatePickers/SimpleDatePicker';
import SimpleTimePicker from './TimePickers/SimpleTimePickerV2/SimpleTimePicker';

import Grid from '@material-ui/core/Grid';
import {getMoment} from "../../logic/timeHandler";
import SlotView from "./debug/SlotView";
import {DebugModeContext} from "../../contexts/DebugModeContext";
import {FirestoreContext} from "../../contexts/FirestoreContextV2";
import {TrackingContext} from "../../contexts/TrackingContextV3";
import createAvailSlotsListener from "../../logic/calendar/slotListener/listenForAvailSlotsV2";
import {useRedDays} from "../../contexts/RedDaysContext";

export default function (props){
    const moment = getMoment();
    const nowMoment = moment();
    const track = useContext(TrackingContext);

    const debugMode = useContext(DebugModeContext);
    const data = useContext(FirestoreContext);
    const { policies } = data;

    const [displayMoment, setDisplayMoment] = useState(nowMoment);   // For navigating months/weeks customHooks. in the calendar
    const [dateSelection, setDateSelection] = useState({
        selectedDate: null,
        availSlots: null
    });

    const {selectedDate, availSlots} = dateSelection;
    const { selectedServiceId } = props;

    // *************************************************
    // *********** Handle redDays **********************
    // *************************************************

    const redDays = useRedDays();
    useEffect(() => {
        const detach = redDays.keepRedDaysDataUpdated();

        return () => {
            detach();
        };
    }, [redDays.keepRedDaysDataUpdated]);

    const redDayCalculated = useMemo(() => {
        try {
            if (
                !redDays.data ||
                !nowMoment ||
                parseInt(nowMoment.format('YYYYMMDD'), 10) !== redDays.data.YYYYMMDD
            ){return null;}

            const serviceId = props.selectedServiceId;
            const service = data.services[serviceId];

            const relevantProviderIds = Object.keys(service.providers)
                .filter(providerId => (service.providers[providerId].publicBooking));

            const res = [];
            for (let i = 0; i < redDays.data.data[relevantProviderIds[0]].length; i++){
                const avail = relevantProviderIds.some(providerId => {
                    const durationInMinutes = (
                        service.hasOwnProperty('durationProviderOverride') &&
                        service.durationProviderOverride.hasOwnProperty(providerId)
                    ) ?
                        service.durationProviderOverride[providerId] :
                        service.defaultDuration;

                    return (durationInMinutes * 60) <= redDays.data.data[providerId][i];
                });

                res.push(!avail);
            }

            return res;
        } catch (err){
            console.error('Error calculating redDayCalculated', err);
            return null;
        }

    }, [redDays.data, nowMoment, props.selectedServiceId]);

    // *************************************************
    // *********** Handle calendar interaction *********
    // *************************************************

    /**
     * @param nextOrPrevious {String} "next" or "previous"
     * @param interval {String} "month", "week" customHooks.
     * @param amount {number} Defaults to 1 (single week customHooks.)
     */
    const navigateInCalendar = useCallback((nextOrPrevious, interval, amount = 1) => {
        setDisplayMoment(oldDisplayMoment => {
            const newDisplayMoment = oldDisplayMoment.clone();

            if (nextOrPrevious === 'next'){
                newDisplayMoment.add(amount, interval);
            } else if (nextOrPrevious === 'previous'){
                newDisplayMoment.subtract(amount, interval);
            } else {
                throw new Error('Should be next or previous.');
            }

            // Confirm max lead time
            const maxLeadTime = moment();
            maxLeadTime.add(policies.maxLeadTime.amount, policies.maxLeadTime.interval);

            const startOfInterval = newDisplayMoment.clone();
            startOfInterval.startOf(interval);

            if (startOfInterval.isAfter(maxLeadTime)){
                return oldDisplayMoment;
            }

            return newDisplayMoment;
        });
    }, [policies, moment]);

    const handleSelectDate = useCallback(newDate => {
        setDateSelection(oldSelection => {
            // If selecting same date twice -> don't do anything
            if (oldSelection.selectedDate && oldSelection.selectedDate.isSame(newDate, 'day')){return oldSelection;}

            const dayOfWeek = newDate.format('dddd');
            const diff = newDate.clone().startOf('day').diff(moment().startOf('day'), 'days');
            track("event", "select_date", {
                days_until: diff.toString(),
                day_of_week: dayOfWeek,
                date: newDate.format('YYYY-MM-DD'),
                dd: newDate.format('DD'),
                mm: newDate.format('MM'),
                yyyy: newDate.format('YYYY')
            });

            return {
                selectedDate: newDate,
                availSlots: null
            };
        });
    }, [track, moment]);

    const {setSelection} = props;
    const handleSelectTime = useCallback((aptStartMoment, providerFilter, venueId) => {
        setSelection("providerFilter", providerFilter);
        setSelection("venueId", venueId);
        setSelection("aptStartMoment", aptStartMoment, "next");

        track("event", "select_time", {
            time: aptStartMoment.format('HH:mm'),
            provider_filter: providerFilter
        });
    }, [setSelection, track]);

    // ***********************************************
    // ******** Handle avail slots listener **********
    // ***********************************************

    let startOfDayTs = null;
    let endOfDayTs = null;
    if (selectedDate){
        const startOfDay = selectedDate.clone();
        startOfDay.startOf('day');
        startOfDayTs = startOfDay.unix();

        const endOfDay = selectedDate.clone();
        endOfDay.endOf('day');
        endOfDayTs = endOfDay.unix();
    }

    const handleAvailSlotsLoaded = useCallback(results => {
        setDateSelection(oldDateSelection => ({
            ...oldDateSelection,
            availSlots: results
        }));
    }, []);

    useEffect(() => {
        if (!startOfDayTs || !endOfDayTs){return;}

        // Create a listener for available slots, and return the cleanup function
        // console.log('CREATING SLOT LISTENER');

        return createAvailSlotsListener(
            data,
            startOfDayTs,
            endOfDayTs,
            selectedServiceId,
            handleAvailSlotsLoaded
        );
    }, [data, startOfDayTs, endOfDayTs, selectedServiceId, handleAvailSlotsLoaded]);

    // *****************************************************
    // ********** Create Date picker child *****************
    // *****************************************************

    const datePickerProps = useMemo(() => {
        return {
            selectedDate: selectedDate,
            selectDate: handleSelectDate,
            displayMoment: displayMoment,
            navigateInCalendar: navigateInCalendar,
            redDayCalculated: redDayCalculated
        }
    }, [selectedDate, displayMoment, navigateInCalendar, handleSelectDate, redDayCalculated]);

    let datePicker;
    switch (data.design.time.date.template) {
        case "SimpleDatePicker":
            datePicker = <SimpleDatePicker {...datePickerProps}/>;
            break;
        default:
            console.error('Could not find component for datePicker template: ', data.design.time.date.template);
            datePicker = <SimpleDatePicker {...datePickerProps}/>;
    }

    // ******************************************************
    // ************* Create Time picker child ***************
    // ******************************************************

    const timePickerProps = useMemo(() => {
        return {
            selectedServiceId: selectedServiceId,
            selectedDate: selectedDate,
            availSlots: availSlots,
            selectTime: handleSelectTime,
            setSelection: setSelection,
            startOfDayTs: startOfDayTs,
            endOfDayTs: endOfDayTs
        };
    }, [availSlots, selectedServiceId, handleSelectTime, selectedDate, setSelection, startOfDayTs, endOfDayTs]);

    let timePicker;
    switch (data.design.time.time.template) {
        case "SimpleTimePicker":
            timePicker = <SimpleTimePicker {...timePickerProps} />;
            break;
        default:
            console.error('Could not find component for timePicker template: ', data.design.time.time.template);
            timePicker = <SimpleTimePicker {...timePickerProps} />;
    }

    return (
        <React.Fragment>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={5} md={4} lg={4}>
                    { datePicker }
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={8}>
                    { timePicker }
                </Grid>
            </Grid>
            { debugMode.debugMode ? (
                <React.Suspense fallback={<div>Loading visual debugger...</div>}>
                    <SlotView {...timePickerProps}/>
                </React.Suspense>) : null }
        </React.Fragment>
    )
}