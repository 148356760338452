import React, {useCallback, useState} from 'react';
import Lightbox from "../containers/p2Service/extras/Lightbox/Lightbox";

const LightboxContext = React.createContext(null);

const LightboxContextProvider = props => {
    const [media, setMedia] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    /**
     * @param {Array<Object>} media - [{mediaId: <string>, desc: <string>}, ...]
     * @param {number} startIndex, optional, which image to start with
     * @type {Function}
     */
    const openLightbox = useCallback((media, startIndex) => {
        setMedia(media);
        setCurrentIndex(startIndex || 0);
    }, [setCurrentIndex, setMedia]);

    const closeLightbox = useCallback(() => {
        setMedia(null);
    }, [setMedia]);

    return(
        <React.Fragment>
            { !!media ? <Lightbox
                media={media}
                onClose={closeLightbox}
                index={currentIndex}
                setIndex={setCurrentIndex}
            /> : null}
            <LightboxContext.Provider value={openLightbox}>
                { props.children }
            </LightboxContext.Provider>
        </React.Fragment>
    );
};

export {LightboxContextProvider, LightboxContext};