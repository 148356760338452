import React, {useContext} from 'react';
import {FirestoreContext} from "../../../contexts/FirestoreContextV2";
import getIdsSortedByOrder from "../../../logic/getIdsSortedByOrder";
import ServiceCircleComponent from "./ServiceCircleComponent";

// Core components
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const ServiceCircles = props => {
    const data = useContext(FirestoreContext);
    const serviceIds = getIdsSortedByOrder(data.services);
    const classes = useStyles();

    return(
        <Grid container spacing={4} className={classes.container}>
            {
                serviceIds.map(serviceId => {
                    return(
                        <Grid item xs={6} sm={4} md={3} lg={2} key={serviceId}>
                            <ServiceCircleComponent
                                service={data.services[serviceId]}
                                onClick={props.selectService(serviceId)}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
};

const useStyles = makeStyles(theme => ({
    container: {
        padding: '0',
        [theme.breakpoints.down('md')]: {
            padding: '0 1rem'
        }
    }
}));

export default ServiceCircles;