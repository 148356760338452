/**
 * This function returns an array containing the venue ids of all venues that can provide the specified service.
 * It takes into hidden venues, hidden hidden providers, and which providers can offer the service.
 * @param {string} serviceId
 * @param {Object} data
 * @return {Array<string>} venueIds
 */
const getEligibleVenueIds = (serviceId, data) => {
    // If the service is hidden -> no eligible venues
    if (data.services[serviceId].hidden){
        console.warn('getEligibleVenueIds with hidden service.');
        return [];
    }

    const allProviderIds = Object.keys(data.providers);
    const service = data.services[serviceId];

    return Object.keys(data.venues).filter(venueId => {
        if (data.venues[venueId].hidden){return false;}

        // Check if there is at least one eligible provider for the service in this venue
        for (let providerId of allProviderIds){
            const provider = data.providers[providerId];
            if (
                provider.venue === venueId &&
                !provider.hidden &&
                service.providers.hasOwnProperty(providerId) &&
                service.providers[providerId].publicBooking
            ){return true;}
        }

        return false;  // Found no eligible provider
    });
};

export default getEligibleVenueIds;