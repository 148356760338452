/*
* National formatting for phone numbers.
* */
const nationalFormats = {
    NO: ['xxx xx xxx'],
    DK: ['xx xx xx xx'],
    PL: ['xxx-xxx-xxx'],
    IS: ['xxx xxxx'],
    BE: ['xxxx xx xx xx', 'xxx xx xx xx'],
    FR: ['xx xx xx xx xx', 'x xx xx xx xx'],  // regular national format | int'l format
    GE: ['xxxxx xxxxxx', 'xxxx xxxxxx'], // DIN 5008 | DIN 5008 international
};

export default nationalFormats;