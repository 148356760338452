import {getMoment} from "../../timeHandler";
import {getFirestore} from "../../firebaseConfig";

/**
 * This function creates a listener to gather all the relevant opening hour adjustments in the selected timeframe.
 * @param {string} businessId
 * @param {number} searchStartTs
 * @param {number} searchEndTs
 * @param {function} callback
 * @return {function} Cleanup function. Removes listener.
 */
const createAdjustmentsListener = (businessId, searchStartTs, searchEndTs, callback) => {
    const moment = getMoment();
    if (!moment){throw new Error('Attempting createAdjustmentListener before moment is loaded.');}

    const firestore = getFirestore();
    if (!firestore){throw new Error('Attempting createAdjustmentListener before firestore is loaded.');}

    const fromYYYYMMDD = moment.unix(searchStartTs).format('YYYYMMDD');
    const toYYYYMMDD = moment.unix(searchEndTs).format('YYYYMMDD');
    let aQry = firestore.collection('openingHoursAdjustments')
        .where('business', "==", businessId)
        .where('date', '>=', parseInt(fromYYYYMMDD, 10))
        .where('date', '<=', parseInt(toYYYYMMDD, 10));

    // return detach function
    return aQry.onSnapshot(snap => {
        const res = {};
        snap.forEach(doc => {
            res[doc.id] = {...doc.data(), id: doc.id};
        });

        callback(res);
    });
};

export default createAdjustmentsListener;