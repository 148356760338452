import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import CircularLoading from "../../components/loading/CircularLoading";

// Core components
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    wrapper: {
        textAlign: 'center',
        width: '100%',
        padding: '5rem 0'
    },
    loading: {
        display: 'inline-block',
        '&>svg': {color: theme.palette.primary.main}
    }
}));

const DependencyLoading = props => {
    const classes = useStyles();

    return(
        <Grid container justifyContent={'center'} alignItems={'center'} className={classes.wrapper}>
            <Grid item xs={5} md={4} xl={3}>
                <CircularLoading/>
            </Grid>
        </Grid>
    )
};

export default DependencyLoading;