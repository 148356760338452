import React, {useEffect} from 'react';
import {LazyLoaderContextProvider} from "./contexts/LazyLoaderContextV2";
import {FirestoreContextProvider} from "./contexts/FirestoreContextV2";
import ModuleContainer from "./containers/p0Container/ModuleContainer";
import ThemeProvider from './contexts/ThemeProvider';
import {DebugModeContextProvider} from "./contexts/DebugModeContext";
import {LocaleStringsContextProvider} from "./contexts/LocaleStringsContext";
import {ErrorBoundary} from "./contexts/ErrorBoundary";
import {TrackingContextProvider} from "./contexts/TrackingContextV3";
import {LightboxContextProvider} from "./contexts/LightboxContext";
import visit from "../data/visit";
import {RedDaysContextProvider} from "./contexts/RedDaysContext";
// import {PerformanceMonitoringContextProvider} from './contexts/PerformanceMonitoringContext';

const BookingModule = () => {
    const businessId = "AGyDlTGN0jPSg8J1ng7L";
    const designId= "wOZcZ1jrZUglIfhNoIC1";
    const visitString = visit();

    // Handle preloading business data from digitalocean server
    // instead of waiting for firebase to load
    useEffect(() => {
        fetch("https://alfalist-instant-availability-node.benediktevippeextensions.no/businessData")
            .then(res => res.json())
            .then(data => {
                window.alfalistServerDefaults = data;
                if (typeof window.alfalistServerDefaultsLoadedCallback === 'function'){
                    window.alfalistServerDefaultsLoadedCallback(window.alfalistServerDefaults);
                }
            }).catch(e => {
                console.warn('Failed to preload buisinss data');
                console.warn(e);
            });
    }, []);

    return (
        <ErrorBoundary>
            <FirestoreContextProvider businessId={businessId} designId={designId} visitString={visitString}>
                <RedDaysContextProvider>
                    <LazyLoaderContextProvider>
                        <TrackingContextProvider>
                            {/*<PerformanceMonitoringContextProvider>*/}
                            <DebugModeContextProvider>
                                <ThemeProvider>
                                    <LocaleStringsContextProvider>
                                        <LightboxContextProvider>
                                            <ModuleContainer/>
                                        </LightboxContextProvider>
                                    </LocaleStringsContextProvider>
                                </ThemeProvider>
                            </DebugModeContextProvider>
                            {/*</PerformanceMonitoringContextProvider>*/}
                        </TrackingContextProvider>
                    </LazyLoaderContextProvider>
                </RedDaysContextProvider>
            </FirestoreContextProvider>
        </ErrorBoundary>
    );
}

export default BookingModule;
