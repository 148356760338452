/**
 * This function replaces *service.name* type placeholders with the appropriate information.
 * @param {string} templateString - template string containing placeholders
 * @param {Object} data - full firestore data object
 * @param {Object} momentStart - moment object for apt start.
 * @param {string} serviceId - serviceId of the selected service.
 * @param {string} venueId - venueId of the selected venue.
 */
const fillPlaceholders = (templateString, data, momentStart, serviceId, venueId) => {
    let template = templateString;
    const service = data.services[serviceId];
    const venue = data.venues[venueId];

    // template = template.replace(/\*newline\*/g, "\n");  // Newlines are hanlded by separate <p>-s locally, do str.split('*newline*') and call fillPlaceholder for each segment
    template = template.replace(/\*time\*/g, momentStart.format('HH:mm'));
    template = template.replace(/\*date\*/g, momentStart.format('DD.MM.YYYY'));
    template = template.replace(/\*service\.name\*/g, service.name);
    // template = template.replace(/\*provider\.name\*/g, provider.name); // Not used locally
    template = template.replace(/\*venue\.name\*/g, venue.name);
    template = template.replace(/\*venue\.locationShort\*/g, venue.locationShort);
    template = template.replace(/\*venue\.locationShortInformal\*/g, venue.locationShortInformal || '');
    template = template.replace(/\*venue\.mapLink\*/g, venue.mapLink);
    template = template.replace(/\*venue\.addressLineOne\*/g, venue.addressLineOne);
    template = template.replace(/\*venue\.addressLineTwo\*/g, venue.addressLineTwo);
    // template = template.replace(/\*cancellationLink\*/g, 'https://alfalistv2.firebaseapp.com/cancel?cancel=' + aptPrivateData.cancellationSlug);  // Not used locally

    return template;
};

export default fillPlaceholders;