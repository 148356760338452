/**
 * This function takes a moment (can be any moment within the interval ('week', 'month', customHooks.) and produces the set
 * of day numbers, and moment-objects to go along with them. Required offset days are also padded in at the start
 * and end. (the greyed-out days from the month before and after to fill in the 7x6 calendar grid).
 * Monday/Sunday as first day of the week is handled in the timeHandler config.
 * @param displayMoment {Object}
 * @param interval {String}
 * @return Array
 */
import {getMoment} from "../timeHandler";

const getDisplayDates = (displayMoment, interval) => {
    const moment = getMoment();
    const today = moment().startOf('day');

    const intervalStart = displayMoment.clone();
    const intervalEnd = displayMoment.clone();

    intervalStart.startOf(interval);
    intervalEnd.endOf(interval);

    const currentDate = intervalStart.clone();
    const lastDate = intervalEnd.clone();

    // Pad with offset
    if (interval === 'month'){
        // Pad start with days from last month
        currentDate.startOf('week');

        // Pad the end to fill all the 42 slots
        const missingDays = 41 - lastDate.diff(currentDate, 'days');
        lastDate.add(missingDays, 'days');
    }

    const ret = [];
    while (currentDate.isSameOrBefore(lastDate)){
        ret.push({
            moment: currentDate.clone(),
            number: currentDate.date(),
            month: currentDate.month() + 1,
            daysInFuture: currentDate.diff(today, 'days'),
            isToday: currentDate.isSameOrBefore(today, 'day'),
            outsideInterval: currentDate.isBefore(intervalStart, 'day') || currentDate.isAfter(intervalEnd, 'day')
        });

        currentDate.add(1, 'day');
    }

    return ret;
};

export default getDisplayDates;