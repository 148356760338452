import React, {useCallback, useContext, useState, useEffect} from 'react';
import CardCategories from "./CardCategories/CardCategories";
import {LazyLoaderContext} from "../../contexts/LazyLoaderContextV2";
import ServiceCircles from "./ServiceCircles/ServiceCircles";
import {FirestoreContext} from "../../contexts/FirestoreContextV2";
import DependencyLoading from "../p1Loading/DependencyLoading";
import {TrackingContext} from "../../contexts/TrackingContextV3";
import getAnalyticsItemFromService from "../../logic/tracking/getAnalyticsItemFromService";
import {useRedDays} from "../../contexts/RedDaysContext";


const ServicesView = props => {
    const data = useContext(FirestoreContext);
    const lazy = useContext(LazyLoaderContext);
    const track = useContext(TrackingContext);

    // Request redDay data when this component is mounted
    // That way it should be ready when the user selects a service
    const redDays = useRedDays();
    useEffect(() => {
        redDays.requestRedDayData();
    }, [redDays.requestRedDayData]);

    const [categoriesOpen, setCategoriesOpen] = useState([]);

    const { registerChange } = lazy;

    const toggleCategoryOpen = useCallback(categoryId => {
        const { services, categories } = data;

        setCategoriesOpen(oldCategoriesOpen => {
            let newState, opening;

            if (oldCategoriesOpen.includes(categoryId)) {
                newState = oldCategoriesOpen.filter(id => id !== categoryId);
                opening = false;

                track("event", "close_category", {
                    category_id: categoryId,
                    category_name: categories[categoryId].name,
                });
            } else {
                newState = [...oldCategoriesOpen];
                newState.unshift(categoryId);
                opening = true;

                track("event", "open_category", {
                    category_id: categoryId,
                    category_name: categories[categoryId].name,
                });
            }

            // Notify lazy-loader
            registerChange.setOpenCategories(newState);
            return (newState);
        });
    }, [track, registerChange, data]);

    const { setSelection } = props;
    const selectService = useCallback(serviceId => {
        const service = data.services[serviceId];

        track("event", "select_service", {
            service_id: serviceId,
            service_name: service.name || 'Unnamed Service',
            category_id: service.category || 'Uncategorized',
            price: service.cents ? (service.cents / 100) : 0,
            order: service.order || 0
        });

        setSelection('serviceId', serviceId, 'next');
    }, [track, setSelection]);

    if (
        !data.design ||
        !data.design.services ||
        !data.design.services.template
    ){
        return <DependencyLoading/>;
    }

    switch(data.design.services.template){
        case "CardCategories":
            return <CardCategories {...props}
                                   toggleCategoryOpen={toggleCategoryOpen}
                                   categoriesOpen={categoriesOpen}
                                   selectService={selectService}
            />;
        case "Bubbles":
            throw new Error('Not implemented');
            // return <FullScreenBubbles {...this.props} selectService={this.selectService}/>;
        case "ServiceCircles":
            return <ServiceCircles selectService={selectService} />;
        default:
            console.error('Unknown template ', data.design.services.template);
            return <CardCategories {...props} />;
    }
};

export default ServicesView;