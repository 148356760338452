import React, {useContext} from 'react';
import MaterialProgress from "./MaterialProgress/MaterialProgress";
import {FirestoreContext} from "../../contexts/FirestoreContextV2";

const ProgressIndicator = props => {
    const data = useContext(FirestoreContext);

    if (!data.design || !data.design.progressIndicator){
        return null;
    }

    switch (data.design.progressIndicator.template){
        case "MaterialProgress":
            return <MaterialProgress onPage={props.onPage} goToPage={props.goToPage}/>;
        default:
            console.error('Could not find component for progress indicator template');
            return <MaterialProgress/>;
    }
};

export default ProgressIndicator;