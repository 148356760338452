/**
 * This function takes a string to be formatted, and fills the characters into the format (where a char is denoted by x)
 * @param str
 * @param format
 */
const replaceXes = (str, format) => {
    const charArray = [...str];  // Convert to array of characters
    return [...format].map(char => char === 'x' ? charArray.shift() : char).join('');
};

export default replaceXes;