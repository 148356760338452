const searchDurationIntervals = {
    searchDurationIntervalKeys: [
        "lessThan3H",
        "lessThan12H",
        "lessThan25H",
        "lessThan48H",
        "lessThan7D",
        "lessThan4W",
        "lessThan1Y",
        "overflow"
    ],
    searchDurationIntervalsInSeconds: {
        lessThan3H: 10800,
        lessThan12H: 43200,
        lessThan25H: 90000,
        lessThan48H: 172800,
        lessThan7D: 604800,
        lessThan4W: 2419200,
        lessThan1Y: 31536000
    }
};

export default searchDurationIntervals;